import { createSelector } from 'reselect';
import {
  URL_PARAM_COMP_ID,
  URL_PARAM_VIDEO_ID,
} from '@wix/wix-vod-shared/dist/src/common/constants/url';
import {
  getCompId,
  getFullSiteUrl,
} from '../redux/hydrated-data/hydrated-data';

import parseQueryParams from '../utils/get-query-params';

const getQueryParams = createSelector(getFullSiteUrl, parseQueryParams);

export const isCurrentComponentIdInUrl = createSelector(
  getQueryParams,
  getCompId,
  (queryParams, compId) =>
    Boolean(compId && queryParams[URL_PARAM_COMP_ID] === compId),
);

export const getVideoIdFromUrl = createSelector(
  getQueryParams,
  isCurrentComponentIdInUrl,
  (queryParams, isCompIdInUrl) =>
    isCompIdInUrl ? queryParams[URL_PARAM_VIDEO_ID] : null,
);

export const getUrlParam = createSelector(
  getQueryParams,
  isCurrentComponentIdInUrl,
  (state, key) => key,
  (queryParams, isCompIdInUrl, key) =>
    isCompIdInUrl ? queryParams[key] : null,
);
