import _ from 'lodash';
import { createAction } from 'redux-actions';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getCurrentSiteUser } from '../../../selectors/current-site-user';
import { canShowChannelCover } from '../../../components/player-overlay/selectors';
import { openPreviewPrompt } from '../../actions/modal';
import { logPlayButtonClicked } from '../../../components/player-overlay/bi';
import * as viewModeSelectors from '../../../selectors/view-mode';
import { createProxy } from '../../../worker/lib';

const PLAY_SELECTED_VIDEO = 'CLIENT.PLAYER_OVERLAY.PLAY_SELECTED_VIDEO';
const playSelectedVideoAction = createAction(PLAY_SELECTED_VIDEO);

export const playSelectedVideo = createProxy(
  'playSelectedVideo',
  ({
      videoItem,
      onPlaySelectedVideo = _.noop,
      shouldSendPlayStartBi = true,
      showChannelCover,
    }) =>
    (dispatch, getState) => {
      const state = getState();
      const channelData = getChannelForWidget(state);
      const currentSiteUser = getCurrentSiteUser(state);
      const isChannelCover = canShowChannelCover(state, { showChannelCover });
      const isPreview = viewModeSelectors.isPreviewMode(state);
      const isEditor = viewModeSelectors.isEditorMode(state);
      const isPreviewOrEditorMode = isPreview || isEditor;

      if (isPreviewOrEditorMode && videoItem.memberOnly) {
        dispatch(openPreviewPrompt());
        return;
      }

      if (shouldSendPlayStartBi) {
        dispatch(
          logPlayButtonClicked({
            channelData,
            videoItem,
            currentSiteUser,
            isChannelCover,
          }),
        );
      }

      onPlaySelectedVideo(videoItem);

      dispatch(playSelectedVideoAction());
    },
);
