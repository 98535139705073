import { createSelector } from 'reselect';

import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getCurrentSiteUser } from '../../../../../../../selectors/current-site-user';
import { getFullAccessActionName } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';

import getTranslationData from './translation-data';
import { getBuyButtonHandler } from '../../../../../../../utils/get-buy-button-handler';

export const createGetPaidAccessButtonConfig = () =>
  createSelector(
    getChannelForWidget,
    getCurrentSiteUser,
    (state, ownProps) => ownProps.videoItem,
    (state, ownProps) => ownProps.onSubscriptionRequest,
    (state, ownProps) => ownProps.onPurchaseRequest,
    (state, ownProps) => ownProps.onRentRequest,
    (
      channel,
      user,
      video,
      onSubscriptionRequest,
      onPurchaseRequest,
      onRentRequest,
    ) => {
      const fullAccessAction = getFullAccessActionName(channel, video, user);

      const callback = getBuyButtonHandler(
        onSubscriptionRequest,
        onPurchaseRequest,
        onRentRequest,
        fullAccessAction,
      );
      const translationData = getTranslationData(
        fullAccessAction,
        channel,
        video,
      );

      return translationData
        ? {
            callback,
            translationData,
          }
        : null;
    },
  );
