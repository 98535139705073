import { combineReducers, Reducer } from 'redux';
import { History } from 'history';
import { connectRouter, RouterState } from 'connected-react-router';

import videos, { VideosState } from './reducers/videos';
import channels, { ChannelsState } from './reducers/channels';
import channelVideos from './reducers/channel-videos';
import configuration from './reducers/configuration';
import appSettings from './reducers/app-settings';
import currentChannelId, {
  CurrentChannelIdState,
} from './reducers/current-channel-id';
import search, { SearchState } from './reducers/search';
import channelInfo from './reducers/channel-info';
import firstChannelVideoId, {
  FirstChannelVideoIdStateAndPayload,
} from './reducers/first-channel-video-id';
import shareOverlay, { ShareOverlayState } from './reducers/share-overlay';
import videoUrlsCache from './reducers/video-urls-cache';
import selectedVideoId, {
  SelectedVideoIdState,
} from './reducers/selected-video-id';
import player from './reducers/player';
import isVideosTouched from './reducers/is-videos-touched';
import videoCards, { VideoCardsState } from './reducers/video-cards';
import payment from './reducers/payment';
import lazyChannelVideos from './lazy-channel-videos/reducer';
import windowSize, { WindowSizeState } from './reducers/window-size';
import isLightbox from './reducers/is-lightbox';
import fullScreenModal, {
  FullScreenModalState,
} from './reducers/full-screen-modal';
import comments, { CommentsState } from './reducers/comments';
import { reducer as hydratedData } from './hydrated-data/hydrated-data';
import playback, { PlaybackState } from './reducers/playback';
import controllerState from './reducers/controller-state';
import { ControllerState } from '../worker/controller-handlers';
import { PaymentInfoState } from './reducers/channel-info/payment-info';
import { InfoState } from './reducers/channel-info/info';

export type RootState = {
  router: RouterState;
  entities: {
    videos: VideosState;
    channels: ChannelsState;
  };
  search: SearchState;
  channelVideos: {
    ids: string[];
    activeRequests: number;
  };
  currentChannelId: CurrentChannelIdState;
  channelInfo: {
    paymentInfo: PaymentInfoState;
    info: InfoState;
  };
  firstChannelVideoId: FirstChannelVideoIdStateAndPayload;
  lazyChannelVideos: any;
  windowSize: WindowSizeState;
  isLightbox: boolean;
  videoUrlsCache: any;
  selectedVideoId: SelectedVideoIdState;
  player: any;
  isVideosTouched: boolean;
  payment: any;
  configuration: any;
  appSettings: any;
  videoCards: VideoCardsState;
  shareOverlay: ShareOverlayState;
  fullScreenModal: FullScreenModalState;
  comments: CommentsState;
  hydratedData: any;
  playback: PlaybackState;
  controllerState: ControllerState;
};

export default ({ history }: { history: History }): Reducer<RootState> =>
  combineReducers<RootState>({
    router: connectRouter(history),
    search,
    entities: combineReducers({
      videos,
      channels,
    }),
    channelVideos,
    currentChannelId,
    channelInfo,
    firstChannelVideoId,
    lazyChannelVideos,
    windowSize,
    isLightbox,
    videoUrlsCache,
    selectedVideoId,
    player,
    isVideosTouched,
    payment,
    configuration,
    appSettings,
    videoCards,
    shareOverlay,
    fullScreenModal,
    comments,
    hydratedData,
    playback,
    controllerState,
  });
