import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import formatMessageWithPrice from '../../utils/format-message-with-price';
import * as dealInfoHelpers from '@wix/wix-vod-shared/dist/src/common/utils/deal-info-helpers';
import { isPricingPlanEnabled } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/channel/pricing';
import { withTranslation } from '@wix/yoshi-flow-editor';

const mapStateToProps = (state, ownProps) => ({
  subscriptionPriceInfo: dealInfoHelpers.getSubscriptionPriceInfo(
    ownProps.dealInfo,
  ),
  isPricingPlan: isPricingPlanEnabled(ownProps),
});

export default withTranslation()(
  connect(mapStateToProps)(
    class ChannelSubscriptionLabel extends React.Component {
      static propTypes = {
        channel: PropTypes.object,
        subscriptionPriceInfo: PropTypes.object,
        dealInfo: PropTypes.array,
      };

      render() {
        const { subscriptionPriceInfo, isPricingPlan } = this.props;

        return isPricingPlan
          ? this.props.t('widget.mobile.overlay-button.subscribe.short')
          : formatMessageWithPrice(
              'widget.channel-actions.subscribe',
              subscriptionPriceInfo,
            );
      }
    },
  ),
);
