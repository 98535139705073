import React from 'react';
import styles from './balls-loader.scss';

export default () => (
  <div className={styles.container}>
    <div className={styles.ballOne} />
    <div className={styles.ballTwo} />
    <div className={styles.ballThree} />
  </div>
);
