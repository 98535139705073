import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  isClassicLayout,
  isStripLayout,
  isCompactLayout,
  isSliderLayout,
  isGridLayout,
  isPlayInFrame,
} from '../../selectors/app-settings';
import { getMainVideoId } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/selected-video-id';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';

import {
  selectVideo,
  resetSelectedVideo,
} from '../../redux/actions/select-video';
import { ensureVideoLoaded } from '../../redux/actions/video/ensure-video-loaded';
import { setSavedTime } from '../../redux/actions/player/set-saved-time';
import { getCurrentChannelId } from '../../selectors/channel-info';

import { requestPlayVideo } from '../../redux/actions/request-play-video';
import {
  openFullScreenVideoOverlay,
  closeFullScreenVideoOverlay,
} from '../../redux/actions/full-screen-modal';

import { PersistentEvents } from '../../components/persistent-events/persistent-events';

const mapStateToProps = (state) => ({
  isClassic: isClassicLayout(state),
  isStrip: isStripLayout(state),
  isCompact: isCompactLayout(state),
  isSlider: isSliderLayout(state),
  isGrid: isGridLayout(state),
  isPlayInFrame: isPlayInFrame(state),
  currentChannelId: getCurrentChannelId(state),
  mainVideoId: getMainVideoId(state),
  channel: getChannelForWidget(state),
});

const mapDispatchToProps = {
  selectVideo,
  ensureVideoLoaded,
  resetSelectedVideo,
  setSavedTime,
  requestPlayVideo,
  openFullScreenVideoOverlay,
  closeFullScreenVideoOverlay,
};

export const PersistentEventsHandler = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class PersistentEventsHandler extends React.Component {
    static propTypes = {
      isClassic: PropTypes.bool.isRequired,
      isStrip: PropTypes.bool.isRequired,
      isCompact: PropTypes.bool.isRequired,
      isSlider: PropTypes.bool.isRequired,
      isGrid: PropTypes.bool.isRequired,
      isPlayInFrame: PropTypes.bool.isRequired,

      currentChannelId: PropTypes.string.isRequired,
      mainVideoId: PropTypes.string.isRequired,

      channel: PropTypes.object.isRequired,

      selectVideo: PropTypes.func.isRequired,
      ensureVideoLoaded: PropTypes.func.isRequired,
      resetSelectedVideo: PropTypes.func.isRequired,
      setSavedTime: PropTypes.func.isRequired,
      requestPlayVideo: PropTypes.func.isRequired,
      openFullScreenVideoOverlay: PropTypes.func.isRequired,
      closeFullScreenVideoOverlay: PropTypes.func.isRequired,
    };

    handleEvent = (eventData) => {
      const { selectedVideoId, savedPlayerTime, openOverlay } = eventData;
      const { resetSelectedVideo, setSavedTime } = this.props;

      if (!selectedVideoId) {
        resetSelectedVideo();
        return;
      }

      if (savedPlayerTime && !openOverlay) {
        setSavedTime(savedPlayerTime);
      }

      this.tryPlayVideoForLayout(eventData);
    };

    tryPlayVideoForLayout(eventData) {
      const { autoplay, selectedVideoId, openOverlay } = eventData;

      const {
        isPlayInFrame,
        isStrip,
        isCompact,
        isGrid,
        isSlider,
        mainVideoId,
        requestPlayVideo,
        selectVideo,
        ensureVideoLoaded,
        channel,
        openFullScreenVideoOverlay,
        closeFullScreenVideoOverlay,
      } = this.props;

      if (openOverlay) {
        const isPlaysLightbox =
          (isCompact || isGrid || isSlider) && !isPlayInFrame;

        if (isStrip || isPlaysLightbox) {
          openFullScreenVideoOverlay(
            channel.id,
            selectedVideoId,
            autoplay,
            closeFullScreenVideoOverlay,
          );
          return;
        }
      }

      ensureVideoLoaded(selectedVideoId).then(() => {
        if (mainVideoId !== selectedVideoId) {
          selectVideo(selectedVideoId);
        }

        if (!isStrip && autoplay) {
          // Strip always plays in lightbox
          requestPlayVideo(selectedVideoId);
        }
      });
    }

    render() {
      return <PersistentEvents onEvent={this.handleEvent} />;
    }
  },
);
