import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import classnames from 'classnames';

import ShareButtons from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/share-buttons/share-buttons';
import { SHARE_MODES } from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/share-buttons/constants';
import ClosableOverlay from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/closable-overlay/closable-overlay';
import Animate from '@wix/wix-vod-shared/dist/src/widget/ui-components/animate/animate';
import { withTranslation } from '@wix/yoshi-flow-editor';
import focus from '@wix/wix-vod-shared/dist/src/widget/utils/accessibility-focus';
import socialNetworks from '@wix/wix-vod-shared/dist/src/widget/constants/social-networks';
import {
  getShareMode,
  isShareOverlayOpen,
  getInstanceInfo,
  getVideoUrl,
  getChannelUrl,
} from '../../selectors/share-overlay';
import { canShowChannelShare, isRTL } from '../../selectors/app-settings';
import {
  getCompId,
  getCurrentPageId,
  getBiToken,
} from '../../redux/hydrated-data/hydrated-data';
import { withHandlersAndState } from '../withHandlersAndState';
import * as viewModeSelectors from '../../selectors/view-mode';
import { canShareVideo } from '../../selectors/video-share';
import { isEmbedEnabled } from '../../selectors/embed';
import { closeShareOverlay } from '../../redux/actions/share-overlay';
import { notForPreview } from '../../utils/not-for-preview';
import { logBi } from '../../worker/actions/bi';
import { STATS_EVENTS } from '../../constants/stats/events';
import EmbedForm from './embed-form/embed-form';

import styles from './share-overlay.scss';

const TRANSLATIONS_MODE_MAP = {
  [SHARE_MODES.VIDEO]: 'share-overlay.social.share',
  [SHARE_MODES.CHANNEL]:
    'widget.compact-view.components.action-bar.share-this-channel',
  CHANNEL_AND_VIDEO: 'share-overlay.social.title',
};

const SHARE_EMBED_ENUM = 'embed';

const mapStateToProps = (state, ownProps) => {
  const isPreview = viewModeSelectors.isPreviewMode(state);
  const isEditor = viewModeSelectors.isEditorMode(state);
  const canShareContent = !(isPreview || isEditor);

  return {
    shareMode: getShareMode(state),
    canShareContent,
    isEditor,
    isChannelShareVisible: _.isUndefined(ownProps.canShareChannel)
      ? canShowChannelShare(state)
      : ownProps.canShareChannel,
    isVideoShareVisible: _.isUndefined(ownProps.canShareVideo)
      ? canShareVideo(state)
      : ownProps.canShareVideo,
    instanceInfo: {
      ...getInstanceInfo(state),
      biToken: getBiToken(state),
    },

    isShareOverlayOpen: isShareOverlayOpen(state),
    shouldShowEmbedButton: isEmbedEnabled(ownProps),
    compId: getCompId(state),
    sitePageId: getCurrentPageId(state),

    // TODO: get links to media share server
    videoUrl: getVideoUrl(state, ownProps),
    channelUrl: getChannelUrl(state),
    isRTL: isRTL(state),
  };
};

const mapDispatchToProps = {
  closeShareOverlay,
  logBi,
};

const ShareOverlay = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class ShareOverlay extends React.Component {
    static propTypes = {
      channelData: PropTypes.object,
      handlers: PropTypes.object,
      videoItem: PropTypes.object,
      instanceInfo: PropTypes.object,
      canShareContent: PropTypes.bool,
      isShareOverlayOpen: PropTypes.bool,
      shouldShowEmbedButton: PropTypes.bool,
      isChannelShareVisible: PropTypes.bool,
      isVideoShareVisible: PropTypes.bool,
      canShareChannel: PropTypes.bool,
      canShareVideo: PropTypes.bool,
      isRTL: PropTypes.bool,
      isEditor: PropTypes.bool,
      shareMode: PropTypes.oneOf([SHARE_MODES.CHANNEL, SHARE_MODES.VIDEO]),

      compId: PropTypes.string,
      sitePageId: PropTypes.string,

      videoUrl: PropTypes.string,
      channelUrl: PropTypes.string,

      closeShareOverlay: PropTypes.func,
      onShare: PropTypes.func,
      onClosed: PropTypes.func,
    };

    static defaultProps = {
      onShare: _.noop,
      onClosed: _.noop,
    };

    constructor(props) {
      super(props);

      this.state = {
        isEmbedOpen: false,
      };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      const { isShareOverlayOpen, isEditor } = this.props;

      // will be closed
      if (isShareOverlayOpen && !nextProps.isShareOverlayOpen) {
        this.closeEmbedForm();
      }

      // back to editor from preview
      if (!isEditor && nextProps.isEditor) {
        this.closeShareOverlay();
      }
    }

    componentDidUpdate(prevProps) {
      const { isShareOverlayOpen } = this.props;

      // is opened
      if (!prevProps.isShareOverlayOpen && isShareOverlayOpen) {
        focus(this.containerRef);
      }
    }

    componentWillUnmount() {
      const { isShareOverlayOpen, closeShareOverlay } = this.props;

      if (isShareOverlayOpen) {
        closeShareOverlay();
      }
    }

    getTitleByShareMode(shareMode) {
      const { isChannelShareVisible, isVideoShareVisible } = this.props;
      if (isChannelShareVisible && isVideoShareVisible) {
        return this.props.t(TRANSLATIONS_MODE_MAP.CHANNEL_AND_VIDEO);
      }

      const translation = TRANSLATIONS_MODE_MAP[shareMode];
      return translation ? this.props.t(translation) : '';
    }

    getTranslations(shareMode) {
      return {
        titleText: this.getTitleByShareMode(shareMode),
        shareChannelText: this.props.t('share-overlay.social.share-channel'),
        shareVideoText: this.props.t('share-overlay.social.share-video'),
        twitterDescriptionText: this.props.t('social-share.network.twitter', {
          title: '{title}',
          siteUrl: '{siteUrl}',
        }),
        pinterestDescriptionText: this.props.t(
          'social-share.network.pinterest',
          {
            title: '{title}',
            description: '{description}',
          },
        ),

        tumblrDescriptionText: this.props.t('social-share.network.tumblr', {
          title: '{title}',
          siteUrl: '{siteUrl}',
        }),
        linkCopiedText: this.props.t('share-overlay.social.link-copied'),

        // tooltips
        embedTooltipText: this.props.t('share-overlay.social.embed-video'),
        facebookTooltipText: this.props.t('social-button.facebook'),
        linkTooltipText: this.props.t('share-overlay.social.copy-link'),
        pinterestTooltipText: this.props.t('social-button.pinterest'),
        tumblrTooltipText: this.props.t('social-button.tumblr'),
        twitterTooltipText: this.props.t('social-button.twitter'),
      };
    }

    getAriaLabels() {
      return {
        facebookAriaLabel: this.props.t('widget.accessibility.share-on', {
          network: 'Facebook',
        }),
        twitterAriaLabel: this.props.t('widget.accessibility.share-on', {
          network: 'Twitter',
        }),
        pinterestAriaLabel: this.props.t('widget.accessibility.share-on', {
          network: 'Pinterest',
        }),
        tumblrAriaLabel: this.props.t('widget.accessibility.share-on', {
          network: 'Tumblr',
        }),
        linkAriaLabel: this.props.t('widget.accessibility.get-link'),
        embedAriaLabel: this.props.t('widget.accessibility.get-embed-code'),
        moreAriaLabel: this.props.t('widget.accessibility.show-more'),
      };
    }

    openEmbedForm = notForPreview(({ shareType }) => {
      this.reportStats({
        networkName: SHARE_EMBED_ENUM,
        shareType,
      });

      this.setState({
        isEmbedOpen: true,
      });
    });

    closeEmbedForm = () => {
      if (!this.state.isEmbedOpen) {
        return;
      }
      this.setState({
        isEmbedOpen: false,
      });
    };

    closeShareOverlay = () => {
      this.props.closeShareOverlay();
      this.props.onClosed();
    };

    saveRef = (ref) => {
      this.containerRef = ref;
    };

    handleShare = notForPreview(({ networkName, shareType }) => {
      const { channelData, videoItem } = this.props;

      this.reportStats({ networkName, shareType });

      this.props.logBi('widget.vid.share.clicked', {
        button: networkName,
        shareType,
        channelID: channelData.id,
        videoID: videoItem.id,
      });
    });

    reportStats({ networkName, shareType }) {
      const { handlers, videoItem, channelData } = this.props;

      if (shareType === SHARE_MODES.VIDEO) {
        handlers.reportStats(STATS_EVENTS.VIDEO_SHARE, {
          channel: channelData,
          videoItem,
          network: {
            name: networkName,
          },
        });
      } else {
        handlers.reportStats(STATS_EVENTS.CHANNEL_SHARE, {
          channel: channelData,
          network: {
            name: networkName,
          },
        });
      }
    }

    handleLinkCopied = notForPreview(({ shareType }) => {
      const { channelData, videoItem } = this.props;

      this.reportStats({
        networkName: socialNetworks.LINK,
        shareType,
      });

      this.props.logBi('widget.vid.share.clicked', {
        button: 'link',
        shareType,
        channelID: channelData.id,
        videoID: videoItem ? videoItem.id : null,
      });
    });

    canShowSelect() {
      const { isChannelShareVisible, isVideoShareVisible, videoItem } =
        this.props;
      return isChannelShareVisible && isVideoShareVisible && Boolean(videoItem);
    }

    getShareMode() {
      const { videoItem, shareMode } = this.props;
      // it should always be in share channel mode if has no video
      return videoItem ? shareMode : SHARE_MODES.CHANNEL;
    }

    render() {
      const {
        instanceInfo,
        canShareContent,
        channelData,
        videoItem,
        isShareOverlayOpen,
        shouldShowEmbedButton,
        videoUrl,
        channelUrl,
        compId,
        sitePageId,
        shareMode,
        isRTL,
      } = this.props;

      const classNames = classnames('share-overlay-container', styles.wrapper, {
        [styles.active]: isShareOverlayOpen,
      });

      const { isEmbedOpen } = this.state;

      return (
        <div
          className={classNames}
          data-hook="share-overlay"
          aria-hidden={!isShareOverlayOpen}
          ref={this.saveRef}
        >
          <Animate
            type="fade-in-out"
            className={`share-overlay-animate ${styles.content}`}
            when={isShareOverlayOpen}
          >
            <ClosableOverlay
              dataHook="closable-overlay"
              onClose={this.closeShareOverlay}
              closeOnBodyClick
              isFullWidth
              isVisible={isShareOverlayOpen}
              isRTL={isRTL}
              ariaLabel={this.props.t('widget.accessibility.share')}
              closeButtonAriaLabel={this.props.t(
                'widget.accessibility.close-share',
              )}
            >
              <ShareButtons
                channelData={channelData}
                videoItem={videoItem}
                shareMode={this.getShareMode()}
                instanceInfo={instanceInfo}
                canShareContent={canShareContent}
                onEmbedClicked={this.openEmbedForm}
                canShowSelect={this.canShowSelect()}
                onShare={this.handleShare}
                onLinkCopied={this.handleLinkCopied}
                shouldShowEmbedButton={shouldShowEmbedButton}
                videoUrl={videoUrl}
                channelUrl={channelUrl}
                isRTL={isRTL}
                {...this.getTranslations(shareMode)}
                {...this.getAriaLabels()}
              >
                {isEmbedOpen && (
                  <EmbedForm
                    dataHook="embed-form"
                    onBack={this.closeEmbedForm}
                    channelId={channelData.id}
                    videoId={videoItem.id}
                    compId={compId}
                    sitePageId={sitePageId}
                    isRTL={isRTL}
                  />
                )}
              </ShareButtons>
            </ClosableOverlay>
          </Animate>
        </div>
      );
    }
  },
);

export default withTranslation()(withHandlersAndState(ShareOverlay));
