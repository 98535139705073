import _ from 'lodash';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import { video as videoSchema } from '../redux/helpers/schemas';
import {
  isFree as isVideoFree,
  isSaleEnabled,
} from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/pricing';
import { PreviewType } from '../redux/types';

function withDealInfoFromSettings(state, video) {
  if (!video) {
    return video;
  }

  video.memberOnly = isVideoFree(video) ? video.memberOnly : false;

  video.allowDownload =
    isVideoFree(video) || isSaleEnabled(video) ? video.allowDownload : false;

  if (_.isEmpty(video.previewType)) {
    video.previewType = isVideoFree(video)
      ? PreviewType.None
      : PreviewType.First20Sec;
  }
  return video;
}

export const getEntities = (state) => state.entities;

export const getVideoEntities = createSelector(
  getEntities,
  _.property('videos'),
);
const getVideosCount = createSelector(getVideoEntities, _.size);

export function isFetching(state) {
  return state.videos.activeRequests !== 0;
}

export function getVideoIds(state) {
  return state.videos && state.videos.ids;
}

export function getVideoById(state, videoId) {
  return getVideoEntities(state)[videoId];
}
export const hasMoreThanOneVideo = createSelector(
  getVideosCount,
  (count) => count > 1,
);

/**
 * @param state
 * @param videoId {String}
 * @returns {Object} video
 */
export const getVideoWithChannelDataById = createSelector(
  [(state) => state, getVideoById],
  (state, videoData) =>
    // TODO remove denormalize. Used to add channel data to usedInLists
    withDealInfoFromSettings(
      state,
      denormalize(videoData, state.entities, videoSchema),
    ),
);

/**
 * @param state
 * @returns {Object} {id: video}
 */
export const getVideosGroupedByIds = createSelector(
  [(state) => state, getVideoEntities],
  (state, videosByIds) =>
    _.mapValues(videosByIds, _.partial(withDealInfoFromSettings, state)),
);

/**
 * @param state
 * @param ids {String[]}
 * @returns {Object[]} videos list
 */
export const getVideosByIds = createSelector(
  [getVideosGroupedByIds, (state, ids) => ids],
  _.pick,
);

/**
 * @param state
 * @param ids {String[]}
 * @param currentVideoId {String}
 * @returns {Object[]} videos list
 */
export const getVideosByIdsWithoutCurrent = createSelector(
  [getVideosByIds, (state, ids, currentVideoId) => currentVideoId],
  _.omit,
);
