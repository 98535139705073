"use strict";

exports.__esModule = true;
exports.default = void 0;
var _default = {
  CARD_CLOSED: 'card-closed',
  CARD_SHOWN: 'card-shown',
  CARD_HIDDEN: 'card-hidden',
  CONFIG_CHANGED: 'config-changed'
};
exports.default = _default;