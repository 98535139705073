import { isCommentsEnabled, isCommentsEnabledForLive } from './app-settings';
import { canAccessFullVideo } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { isDemoChannel } from '../utils/channel-helpers';
import { isExternalVideo } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/video-origin';
import {
  isStreamingLive,
  isScheduledLive,
} from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/live';

export const canShowComments = (
  state,
  videoItem,
  channelData,
  currentSiteUser,
) =>
  ((!isStreamingLive(videoItem) && isCommentsEnabled(state)) ||
    (isStreamingLive(videoItem) && isCommentsEnabledForLive(state))) &&
  !isDemoChannel(channelData.id) &&
  !isExternalVideo(videoItem) &&
  !isScheduledLive(videoItem) &&
  canAccessFullVideo(channelData, videoItem, currentSiteUser);

export const isCommentsOpen = (state) => state.comments.isOpen;
export const shouldOpenCommentsOnLoad = (state) => state.comments.openOnLoad;
export const hasNewComments = (state) => state.comments.hasNewComments;
