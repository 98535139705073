import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ensureVideoLoaded } from '../../../../../redux/actions/video/ensure-video-loaded';
import { selectVideo } from '../../../../../redux/actions/select-video';
import {
  playVideo,
  pauseVideo,
} from '../../../../../redux/actions/player/change-playback-status';
import { getCurrentSiteUser } from '../../../../../selectors/current-site-user';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getVideosGroupedByIds } from '../../../../../selectors/videos';
import { canPlayFullOrPreview } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { getVideoCardsList } from '../../../../../selectors/video-cards';
import VideoCardsWrapper from '../../../../../components/video-cards-wrapper/video-cards-wrapper';

const mapStateToProps = (state) => ({
  cardsData: getVideoCardsList(state),
  currentSiteUser: getCurrentSiteUser(state),
  videoByIds: getVideosGroupedByIds(state),
  channelData: getChannelForWidget(state),
});

const mapDispatchToProps = {
  selectVideo,
  playVideo,
  pauseVideo,
  ensureVideoLoaded,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class VideoCardsWrapperDesktop extends React.PureComponent {
    static propTypes = {
      currentSiteUser: PropTypes.object,
      channelData: PropTypes.object,
      videoByIds: PropTypes.object,
      cardsData: PropTypes.array,

      children: PropTypes.func.isRequired,

      selectVideo: PropTypes.func,
      playVideo: PropTypes.func,
      pauseVideo: PropTypes.func,
      ensureVideoLoaded: PropTypes.func,

      thumbnailSize: PropTypes.number,
    };

    handlePlayRequest = ({ id }) => {
      const {
        channelData,
        currentSiteUser,
        selectVideo,
        playVideo,
        ensureVideoLoaded,
      } = this.props;

      ensureVideoLoaded(id).then(() => {
        const videoItem = this.props.videoByIds[id];
        const isPlayAllowed = canPlayFullOrPreview(
          channelData,
          videoItem,
          currentSiteUser,
        );

        if (isPlayAllowed) {
          playVideo(videoItem.id);
          return;
        }

        selectVideo(videoItem.id);
      });
    };

    render() {
      return (
        <VideoCardsWrapper
          {...this.props}
          handlePlayRequest={this.handlePlayRequest}
        />
      );
    }
  },
);
