import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isButtonsTextShown } from '../../../../../selectors/app-settings';
import { purchaseButtonText } from '../../ui-selectors/buttons';
import { CurrencySign } from '@wix/wix-vod-shared/dist/src/common/components/currency-sign';
import styles from './purchase-button.scss';
import classNames from 'classnames';
import { getCurrency } from '../../../../../selectors/currency';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';

const mapStateToProps = (state, props) => ({
  text: purchaseButtonText(state, props),
  isTextShown: isButtonsTextShown(state),
  channel: getChannelForWidget(state),
});

export default connect(mapStateToProps)(
  class PurchaseButton extends React.Component {
    static propTypes = {
      channel: PropTypes.object.isRequired,
      videoItem: PropTypes.object.isRequired,
      text: PropTypes.object,

      iconClassName: PropTypes.string,

      onClick: PropTypes.func.isRequired,
      isTextShown: PropTypes.bool,

      className: PropTypes.string,
    };

    render() {
      const {
        text,
        onClick,
        iconClassName,
        className,
        isTextShown,
        videoItem,
        channel,
      } = this.props;
      const currency = getCurrency(videoItem, channel);

      return text ? (
        <div
          data-hook="paid-access-button"
          className={className}
          onClick={onClick}
        >
          <div className={classNames(styles.purchaseIcon, iconClassName)}>
            <CurrencySign currency={currency} />
          </div>
          {isTextShown && text}
        </div>
      ) : null;
    }
  },
);
