import { PaymentType } from '@wix/wix-vod-constants/dist/api-types/enums';

export const PAYMENT_PERMISSIONS = {
  UNLIMITED_STREAMING: 'unlimitedStreaming',
  INSTANT_ACCESS: 'instantAccess',
  MONTHLY_PAYMENT: 'monthlyPayment',
  SINGLE_VIDEO: 'singleVideo',
  ONE_PAYMENT: 'onePayment',
  SINGLE_VIDEO_STREAMING: 'singleVideoStreaming',
  STREAMING_PERIOD: 'streamingPeriod',
  UNLIMITED_DOWNLOADS: 'unlimitedDownloads',
  DOWNLOAD_ANY_TIME: 'downloadAnytime',
};

const {
  UNLIMITED_STREAMING,
  INSTANT_ACCESS,
  MONTHLY_PAYMENT,
  SINGLE_VIDEO,
  ONE_PAYMENT,
  SINGLE_VIDEO_STREAMING,
  STREAMING_PERIOD,
  UNLIMITED_DOWNLOADS,
  DOWNLOAD_ANY_TIME,
} = PAYMENT_PERMISSIONS;

const PAYMENT_PERMS = {
  [PaymentType.SUBSCRIPTION]: [
    UNLIMITED_STREAMING,
    INSTANT_ACCESS,
    MONTHLY_PAYMENT,
  ],
  [PaymentType.PURCHASE]: [SINGLE_VIDEO, ONE_PAYMENT, UNLIMITED_STREAMING],
  [PaymentType.RENT]: [SINGLE_VIDEO_STREAMING, STREAMING_PERIOD],
  [PaymentType.MEMBER_ONLY]: [SINGLE_VIDEO, UNLIMITED_STREAMING],
  [PaymentType.PLAN]: [UNLIMITED_STREAMING, INSTANT_ACCESS],
};

const DOWNLOAD_PERMS = {
  [PaymentType.SUBSCRIPTION]: [UNLIMITED_DOWNLOADS],
  [PaymentType.PURCHASE]: [DOWNLOAD_ANY_TIME],
  [PaymentType.RENT]: [],
  [PaymentType.MEMBER_ONLY]: [DOWNLOAD_ANY_TIME],
};

export function getPaymentPermissions({ paymentType, downloadEnabled }) {
  const permissions = PAYMENT_PERMS[paymentType];
  return downloadEnabled
    ? permissions.concat(DOWNLOAD_PERMS[paymentType])
    : permissions;
}

export const CHARGE_INTENT = {
  SALE: 'SALE',
  RECURRING: 'RECURRING',
};

export const CHARGE_INTENT_BY_TYPE = {
  [PaymentType.SUBSCRIPTION]: CHARGE_INTENT.RECURRING,
  [PaymentType.PURCHASE]: CHARGE_INTENT.SALE,
  [PaymentType.RENT]: CHARGE_INTENT.SALE,
  [PaymentType.MEMBER_ONLY]: null,
};

export const VIDEO_PAYMENT_LOGOUT_KEY = 'videoPaymentLogOut';
