import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PaymentEvents from '../components/payment-events/payment-events';

import { getChannelIdFromAppSettings } from '../selectors/app-settings';
import { getPaymentInfo } from '../redux/actions/channel/payment';

const mapStateToProps = (state) => ({
  channelId: getChannelIdFromAppSettings(state),
});

const mapDispatchToProps = {
  getPaymentInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class ReloadChannelPaymentDataOnPayment extends React.Component {
    static propTypes = {
      getPaymentInfo: PropTypes.func,
      channelId: PropTypes.string,
    };

    fetchChannelPaymentData = _.debounce(() => {
      this.props.getPaymentInfo(this.props.channelId);
    }, 300);

    render() {
      return <PaymentEvents onAnyEvent={this.fetchChannelPaymentData} />;
    }
  },
);
