import _ from 'lodash';
import { durationToISOString } from '../../utils/duration';
import {
  getResizedImageUrl,
  MODES,
} from '@wix/wix-vod-shared/dist/src/common/utils/get-resized-image-url';
import { PublicChannelItem, PublicVideoItem } from '@wix/wix-vod-api/public';
import { PublicChannelItem as PublicChannelItemV2 } from '@wix/wix-vod-api-site-based/dist/src/public/services/channel/channel';

export interface ChannelSeoData {
  name?: string;
  description?: string;
  thumbnailUrl?: string;
  keywords?: string[];
}

export interface VideoSeoData {
  thumbnailUrl: string;
  description: string;
  name: string;
  uploadDate: string; // YYYY-MM-DD
  duration: string; //	The duration of the video in ISO 8601 format	ISO 8601	"PT1M54S"
  contentUrl: string;
  keywords: string[];
}

export interface SeoData extends ChannelSeoData {
  videos: VideoSeoData[];
}

const getVideoKeywords = (data: PublicVideoItem): string[] => {
  const keywords: string[] = [];

  if (data.categories && data.categories.length) {
    keywords.push(data.categories.join(', '));
  }
  if (data.tags && data.tags.length) {
    keywords.push(data.tags.join(', '));
  }
  if (data.crew) {
    data.crew.forEach((crewItem) => {
      keywords.push(crewItem.role);
      keywords.push(crewItem.name);
    });
  }
  if (data.cast) {
    data.cast.forEach((castItem) => {
      keywords.push(castItem.role);
      keywords.push(castItem.name);
    });
  }

  return keywords;
};

const getVideoContentUrls = (data: PublicVideoItem) => {
  return {
    embedUrl: data.mediaExternUrl || data.contentUrl,
    contentUrl: data.contentUrl,
  };
};

const getThumbnailUrl = (url: string) =>
  getResizedImageUrl({
    url,
    height: 330,
    width: 330,
    mode: MODES.fill,
  });

const getChannel = (
  data: PublicChannelItem | PublicChannelItemV2,
): ChannelSeoData => {
  const keywords: string[] = [];
  const channel = data as PublicChannelItem;
  const channelAsV2 = data as PublicChannelItemV2;
  const { categories, tags } =
    channel.statsInfo || channelAsV2.stats_info || {};
  if (categories && categories.length) {
    keywords.push(categories.map((c) => c.value).join(', '));
  }
  if (tags && tags.length) {
    keywords.push(tags.map((c) => c.value).join(', '));
  }
  return {
    name: data.title,
    description: data.description || undefined,
    thumbnailUrl: getThumbnailUrl(
      (data as any).customCoverUrl || channel.coverUrl,
    ),
    keywords,
  };
};

const getVideo = (data: PublicVideoItem): VideoSeoData => {
  const { embedUrl, contentUrl } = getVideoContentUrls(data);
  const thumbnailUrl = getThumbnailUrl(
    (data as any).customCoverUrl || data.posterUrl || data.coverUrl,
  );
  return {
    name: data.title,
    thumbnailUrl,
    contentUrl: embedUrl || contentUrl,
    uploadDate: data.datePublish,
    description: data.description || data.title,
    duration: durationToISOString(data.durationSec || 0),
    keywords: getVideoKeywords(data),
  };
};

const videoHasAllRequiredProperties = (video: PublicVideoItem) =>
  Boolean(video && _.trim(video.title));

export interface GetSeoDataParams {
  channel?: PublicChannelItem;
  videos: PublicVideoItem[];
}

export const getSeoData = ({ channel, videos }: GetSeoDataParams): SeoData => {
  return {
    ...(channel ? getChannel(channel) : {}),
    videos: videos.filter(videoHasAllRequiredProperties).map(getVideo),
  };
};
