import _ from 'lodash';
import { PaymentType } from '@wix/wix-vod-constants/dist/api-types/enums';
import CLOSE_REASONS from '../full-screen-modals/constants/close-reasons';
import {
  openFullScreenOverlay,
  openMobileOverlay,
} from '../worker/actions/openOverlay';

export { openFullScreenOverlay, openMobileOverlay };

export function openFullScreenSubscribeOverlay(
  channelId,
  onCloseByUser = _.noop,
  isPricingPlan,
) {
  const paymentType = isPricingPlan
    ? PaymentType.PLAN
    : PaymentType.SUBSCRIPTION;

  return openFullScreenOverlay(
    `#/payment/${paymentType}/${channelId}`,
    (reason) => {
      if (reason === CLOSE_REASONS.CLOSED_BY_USER) {
        onCloseByUser();
      }
    },
  );
}

function openPaymentOverlay(channelId, videoId, paymentType) {
  const route = channelId
    ? `#/payment/${paymentType}/${channelId}/${videoId}` // channel video
    : `#/payment/${paymentType}/video/${videoId}`; // single video
  return openFullScreenOverlay(route);
}

export function openFullScreenPurchaseOverlay(channelId, videoId) {
  return openPaymentOverlay(channelId, videoId, PaymentType.PURCHASE);
}

export function openFullScreenRentOverlay(channelId, videoId) {
  return openPaymentOverlay(channelId, videoId, PaymentType.RENT);
}

export function openFullScreenMemberOnlyOverlay(channelId, videoId) {
  return openPaymentOverlay(channelId, videoId, PaymentType.MEMBER_ONLY);
}

export function openFullScreenChannelOverlay(channelId) {
  return openFullScreenOverlay(`#/channel/${channelId}/info`);
}

export function openFullScreenShareOverlay(channelId, videoId) {
  const baseUrl = `#/channel/${channelId}/share/`;
  return openFullScreenOverlay(videoId ? `${baseUrl}${videoId}` : baseUrl);
}
