import * as React from 'react';
import Spinner from '@wix/wix-vod-shared/dist/src/widget/ui-components/loading-spinner/loading-spinner';

import styles from './spinner.scss';

export const LayoutSpinner = () => (
  <div className={styles['spinner-container']}>
    <Spinner className={styles.spinner} />
  </div>
);
