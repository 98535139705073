import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getWixSDK } from '../../utils/wix-sdk';
import { updateAppSettings } from '../../redux/actions/app-settings';
import { getChannelIdFromAppSettings } from '../../selectors/app-settings';
import { getChannelId } from '../../utils/app-settings';
import * as viewModeSelectors from '../../selectors/view-mode';

import WixEvents from '../../constants/wix-sdk';
import {
  withPubSubEvents,
  consumePubSubEvent,
} from '../../containers/pub-sub-events';

const mapStateToProps = (state) => ({
  appSettings: state.appSettings,
  currentChannelId: getChannelIdFromAppSettings(state),
  isSiteMode: viewModeSelectors.isSiteMode(state),
});

const mapDispatchToProps = {
  updateAppSettings,
};

export const AppSettingsObserver = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withPubSubEvents(
    class AppSettingsObserver extends React.Component {
      static propTypes = {
        children: PropTypes.func,
        appSettings: PropTypes.object,
        isSiteMode: PropTypes.bool.isRequired,
        currentChannelId: PropTypes.string,
        updateAppSettings: PropTypes.func.isRequired,
      };

      handleEditModeChange = _.debounce(({ editMode }) => {
        this.props.updateAppSettings({ editMode });
      }, 100);

      handleStylesChange = _.debounce((styleParams) => {
        const { currentChannelId, isSiteMode } = this.props;

        getWixSDK()
          .Styles.extendStylesWithInitialChannelId(styleParams)
          .then((updatedStyles) => {
            const isChanged = currentChannelId !== getChannelId(updatedStyles);

            if (isSiteMode) {
              return;
            }

            if (isChanged) {
              window.location.reload();
            } else {
              this.props.updateAppSettings(updatedStyles);
            }
          });
      }, 100);

      componentDidUpdate(prevProps) {
        consumePubSubEvent(
          WixEvents.STYLE_PARAMS_CHANGE,
          this.handleStylesChange,
          {
            prevProps,
            nextProps: this.props,
          },
        );
        consumePubSubEvent(
          WixEvents.EDIT_MODE_CHANGE,
          this.handleEditModeChange,
          {
            prevProps,
            nextProps: this.props,
          },
        );
      }

      render() {
        const { children, appSettings } = this.props;

        if (children) {
          return children(appSettings);
        }

        return null;
      }
    },
  ),
);
