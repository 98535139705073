import _ from 'lodash';
import { createSelector } from 'reselect';
import { getVideoIdFromAppSettings, isSingleVideo } from './app-settings';
import { getVideoIdFromUrl } from './query-params';
import { getVideosGroupedByIds } from './videos';
import { dashify } from '@wix/wix-vod-shared/dist/src/common/utils/guid';
import { isGraphAPIEnabled } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';

export const getVideoIdToSelect = createSelector(
  [isSingleVideo, getVideoIdFromAppSettings, getVideoIdFromUrl],
  (isSingleVideo, singleVideoId, videoIdFromUrl) => {
    const videoIdToSelect = isSingleVideo ? singleVideoId : videoIdFromUrl;

    return isGraphAPIEnabled() ? dashify(videoIdToSelect) : videoIdToSelect;
  },
);

export const videoToSelectExists = createSelector(
  [getVideoIdToSelect, getVideosGroupedByIds],
  (videoIdToSelect, videos) => {
    return !_.isEmpty(videos[videoIdToSelect]);
  },
);
