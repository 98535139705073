import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ClampText from '@wix/wix-vod-shared/dist/src/common/components/clamp-text/clamp-text';

import styles from '../overlay.scss';

export default class OverlayTitle extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.string,
    maxLinesCount: PropTypes.number,
    ariaHidden: PropTypes.bool,
  };

  static defaultProps = {
    maxLinesCount: 2,
    ariaHidden: false,
  };

  render() {
    const { children, maxLinesCount, className, ariaHidden } = this.props;

    if (!children) {
      return null;
    }

    return (
      <div
        className={classnames(styles.title, className)}
        aria-hidden={ariaHidden}
      >
        <ClampText
          text={children}
          dataHook="title"
          wrapperTag="h3"
          clampOptions={{ lineClamp: maxLinesCount }}
        />
      </div>
    );
  }
}
