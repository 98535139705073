import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import memoizedPartial from '@wix/wix-vod-shared/dist/src/common/utils/memoized-partial';
import ActionsStrip from '../../actions-strip/actions-strip';
import styles from './compact.scss';
import CoverWithOverlay from '../../cover-with-overlay/cover-with-overlay';
import SlidableListWithLazyLoad from '../../slidable-list-with-lazy-load';
import SlideMenu from '../../slide-menu/slide-menu';
import SlideMenuButton from '../../buttons/slide-menu-button';
import SubscribeButton from '../../buttons/subscribe-button';
import NavigationDots from '../../navigation-dots/navigation-dots';
import { getChannelCoverUrl } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/channel/cover';
import AutoPlayVideo from '../../../../../../components/autoplay-video/autoplay-video';
import { showAutoPlay } from '../../../../../../selectors/layout';
import { isRTL } from '../../../../../../selectors/app-settings';
import { PictureMode } from '@wix/wix-vod-shared/dist/src/common/components/picture';

const mapStateToProps = (state) => ({
  isSlideMenuOpened: state.slideMenuOpened,
  showAutoPlay: showAutoPlay(state),
  windowSize: state.windowSize,
  isRTL: isRTL(state),
});

export default connect(mapStateToProps)(
  class Compact extends React.Component {
    static propTypes = {
      overlaySize: PropTypes.object.isRequired,
      playerSize: PropTypes.object.isRequired,
      channelData: PropTypes.object.isRequired,
      videoByIds: PropTypes.object.isRequired,
      currentSiteUser: PropTypes.object,
      mainVideo: PropTypes.object,

      selectVideo: PropTypes.func.isRequired,
      playVideo: PropTypes.func.isRequired,
      pauseVideo: PropTypes.func.isRequired,
      openVideoPage: PropTypes.func.isRequired,
      showLogInForm: PropTypes.func.isRequired,
      onCurrentSlideChanged: PropTypes.func.isRequired,
      openMemberOnlyPage: PropTypes.func.isRequired,
      onSubscribeClick: PropTypes.func,
      onPlayRequestedDisallowed: PropTypes.func.isRequired,
      loadPlaybackModule: PropTypes.func.isRequired,

      showAutoPlay: PropTypes.bool,
      isMainItemChannelCover: PropTypes.bool,
      isVideoPlaying: PropTypes.bool.isRequired,
      isSingleVideoView: PropTypes.bool,
      isSlideMenuOpened: PropTypes.bool,
      isRTL: PropTypes.bool,

      initialVideoId: PropTypes.string,
      mainVideoId: PropTypes.string,
      videoIds: PropTypes.array,
    };

    static defaultProps = {
      onSubscribeClick: _.noop,
    };

    constructor(props) {
      super(props);
      this.state = {
        PlaybackComponent: null,
        isMainCoverOverlayOn: true,
        selectedIndex: _.indexOf(props.videoIds, props.mainVideoId, 0),
      };
    }

    componentDidMount() {
      this.props
        .loadPlaybackModule()
        .then((PlaybackComponent) => {
          this.setState({
            PlaybackComponent,
          });
        })
        .catch(_.noop);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      const { isVideoPlaying } = nextProps;
      if (this.props.isVideoPlaying !== isVideoPlaying && isVideoPlaying) {
        this.closeCover();
      }
    }

    showCover = () => {
      this.setState({
        isMainCoverOverlayOn: true,
      });
    };

    closeCover = () => {
      this.setState({
        isMainCoverOverlayOn: false,
      });
    };

    renderSubscribeButton(videoItem) {
      const { onSubscribeClick } = this.props;
      return (
        <SubscribeButton
          className={styles.subscribe}
          onClick={onSubscribeClick}
          videoItem={videoItem}
        />
      );
    }

    renderTopMenuArea(videoItem, isFirstVideo) {
      const { channelData } = this.props;

      return (
        <div data-hook="top-menu-area" className={styles['top-menu-area']}>
          {this.renderSubscribeButton(videoItem)}
          <div className={styles['slide-menu-icon-wrapper']}>
            <SlideMenuButton
              className={styles['slide-menu-icon-wrapper']}
              iconClassName={styles['slide-menu-icon']}
              videoItem={videoItem}
              channelData={channelData}
              isFirstVideo={isFirstVideo}
            />
          </div>
        </div>
      );
    }

    renderNoVideosCover() {
      const {
        channelData,
        overlaySize: { width, height },
      } = this.props;

      return (
        <CoverWithOverlay
          channelData={channelData}
          showChannelCover
          itemWidth={width}
          itemHeight={height}
          onSignInRequested={_.noop}
          className={styles['overlay-only']}
          coverUrl={getChannelCoverUrl(channelData)}
        />
      );
    }

    renderCover(videoItem, isFirstVideo, className = null) {
      const {
        overlaySize: { width, height },
        onPlayRequestedDisallowed,
        showLogInForm,
      } = this.props;

      return (
        <React.Fragment>
          {this.renderTopMenuArea(videoItem, isFirstVideo)}
          <CoverWithOverlay
            videoItem={videoItem}
            className={className}
            itemWidth={width}
            itemHeight={height}
            isFirstVideo={isFirstVideo}
            onSignInRequested={showLogInForm}
            onPlayRequestedDisallowed={onPlayRequestedDisallowed}
            onPlayRequestedAllowed={this.onPlayRequestedAllowed}
            onMemberSignUp={memoizedPartial(
              this.props.openMemberOnlyPage,
              videoItem.id,
            )}
            pictureMode={PictureMode.CONTAIN}
          />
        </React.Fragment>
      );
    }

    renderCoverCarousel() {
      const { videoIds, videoByIds } = this.props;

      return _(videoIds)
        .filter((videoId) => Boolean(videoByIds[videoId]))
        .map((videoId, index) => (
          <div key={videoId} role="presentation">
            {this.renderCover(
              videoByIds[videoId],
              index === 0,
              styles['overlay-item-topmost'],
            )}
          </div>
        ))
        .value();
    }

    renderSlideMenu(videoItem, isFirstVideo) {
      const { isSlideMenuOpened, channelData } = this.props;
      if (!isSlideMenuOpened || !videoItem) {
        return null;
      }

      return (
        <SlideMenu
          videoItem={videoItem}
          channelData={channelData}
          onMemberSignUp={memoizedPartial(
            this.props.openMemberOnlyPage,
            videoItem.id,
          )}
          isFirstVideo={isFirstVideo}
        />
      );
    }

    beforeCurrentSlideChanged = (oldIndex, newIndex) => {
      this.setState({
        selectedIndex: newIndex,
      });
    };

    onCurrentSlideChanged = (newIndex) => {
      this.props.onCurrentSlideChanged(newIndex);
    };

    renderNavigationDots() {
      const { videoIds, isRTL } = this.props;
      return (
        <div className={styles['navigation-dots']}>
          <NavigationDots
            count={_.size(videoIds)}
            selectedIndex={this.state.selectedIndex || 0}
            isRTL={isRTL}
          />
        </div>
      );
    }

    renderMultipleVideosOverlay() {
      const {
        overlaySize: { height },
      } = this.props;
      const { mainVideoId, videoIds, videoByIds } = this.props;
      const isFirstVideo = _.startsWith(videoIds, mainVideoId);

      return (
        <div>
          <SlidableListWithLazyLoad
            itemHeight={height}
            className={styles['players-list-slidable']}
            onCurrentSlideChanged={this.onCurrentSlideChanged}
            beforeCurrentSlideChanged={this.beforeCurrentSlideChanged}
            initialVideoId={this.props.mainVideoId}
          >
            {this.renderCoverCarousel()}
          </SlidableListWithLazyLoad>
          {this.renderNavigationDots()}
          {this.renderSlideMenu(videoByIds[mainVideoId], isFirstVideo)}
        </div>
      );
    }

    renderSingleVideoOverlay() {
      const { mainVideo } = this.props;
      // NOTE: single-video-slick-active class is used for test purposes only
      const className = `${styles['single-item-cover']} single-video-slick-active`;
      return (
        <div className={className} role="presentation">
          {this.renderCover(mainVideo, true)}
          {this.renderSlideMenu(mainVideo)}
        </div>
      );
    }

    renderMainCoverOverlay() {
      const { isSingleVideoView } = this.props;
      const { isMainCoverOverlayOn } = this.state;
      const className = classnames(styles['main-cover-overlay'], {
        [styles.visible]: isMainCoverOverlayOn,
      });

      return (
        <div data-hook="main-cover-overlay" className={className}>
          {isSingleVideoView
            ? this.renderSingleVideoOverlay()
            : this.renderMultipleVideosOverlay()}
        </div>
      );
    }

    // TODO: make it not dirty
    renderPlayer() {
      const {
        isVideoPlaying,
        mainVideo,
        channelData,
        openMemberOnlyPage,
        playerSize: { width, height },
      } = this.props;
      const { PlaybackComponent } = this.state;

      if (!PlaybackComponent) {
        return null;
      }

      return (
        <PlaybackComponent
          videoItem={mainVideo}
          channelData={channelData}
          paused={!isVideoPlaying}
          onEnded={this.onPlayEnded}
          width={width}
          height={height}
          onMemberSignUp={openMemberOnlyPage}
        />
      );
    }

    onPlayRequestedAllowed = () => {
      const { mainVideo, playVideo } = this.props;
      playVideo(mainVideo);
      this.closeCover();
    };

    onPlayEnded = () => {
      if (!this.props.isVideoPlaying) {
        this.showCover();
      }
    };

    onClosePlayerClick = () => {
      const { mainVideo, pauseVideo } = this.props;

      pauseVideo(mainVideo.id);
      this.showCover();
    };

    renderPlayerWithActions() {
      const { mainVideo, channelData, showAutoPlay } = this.props;
      const { isMainCoverOverlayOn } = this.state;
      const styleName = classnames(styles['player-with-actions'], {
        [styles['cover-on']]: isMainCoverOverlayOn,
      });

      return (
        <div data-hook="player-with-actions" className={styleName}>
          {!this.state.isMainCoverOverlayOn && (
            <ActionsStrip
              videoItem={mainVideo}
              channelData={channelData}
              className={styles['action-strip']}
              onCloseClick={this.onClosePlayerClick}
            />
          )}
          <div className={styles.player}>
            {this.renderPlayer()}
            {showAutoPlay && <AutoPlayVideo isMobile />}
          </div>
        </div>
      );
    }

    render() {
      const {
        mainVideo,
        overlaySize: { width, height },
      } = this.props;
      const { isMainCoverOverlayOn } = this.state;

      if (!mainVideo) {
        return this.renderNoVideosCover();
      }

      const styleName = classnames(styles['main-ui'], {
        [styles['cover-on']]: isMainCoverOverlayOn,
      });

      return (
        <div
          data-hook="main-ui"
          className={styleName}
          style={{ width, height }}
        >
          {this.renderMainCoverOverlay()}
          {this.renderPlayerWithActions()}
        </div>
      );
    }
  },
);
