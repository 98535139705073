import * as dealInfoHelpers from '@wix/wix-vod-shared/dist/src/common/utils/deal-info-helpers.js';
import { isPricingPlanEnabled } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/channel/pricing';

export default function getTranslationData(channel) {
  const isPricingPlan = isPricingPlanEnabled(channel);

  return {
    props: isPricingPlan
      ? 'widget.mobile.overlay-button.subscribe.short'
      : 'widget.overlay-buttons.get-subscription',
    priceModel: isPricingPlan
      ? dealInfoHelpers.getPricingPlanInfo(channel.dealInfo)
      : dealInfoHelpers.getSubscriptionPriceInfo(channel.dealInfo),
  };
}
