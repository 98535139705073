import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { notForPreview } from '../../utils/not-for-preview';
import { getMainVideoId } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/selected-video-id';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { isShareOverlayOpen } from '../../selectors/share-overlay';
import { openFullScreenShareOverlay } from '../../utils/open-overlay-base';
import { isInlineShareVisible } from '../../selectors/inline-share';
import { pauseVideo } from '../../redux/actions/player/change-playback-status';
import { closeShareOverlay } from '../../redux/actions/share-overlay';
import { showPlayerUI } from '../../redux/actions/player/ui';

const mapStateToProps = (state, props) => ({
  isShareOpen: isShareOverlayOpen(state),
  mainVideoId: getMainVideoId(state),
  channel: getChannelForWidget(state),
  shouldOpenFullscreenModal: !isInlineShareVisible(state, props),
});

const mapDispatchToProps = {
  pauseVideo,
  closeShareOverlay,
  showPlayerUI,
  openFullScreenShareOverlay,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class OpenFullscreenModalShare extends React.Component {
    static propTypes = {
      pauseVideo: PropTypes.func.isRequired,
      closeShareOverlay: PropTypes.func.isRequired,
      showPlayerUI: PropTypes.func.isRequired,
      isShareOpen: PropTypes.bool.isRequired,
      shouldOpenFullscreenModal: PropTypes.bool.isRequired,
      mainVideoId: PropTypes.string,
      channel: PropTypes.object,
      itemWidth: PropTypes.number.isRequired,
    };

    UNSAFE_componentWillReceiveProps({
      pauseVideo,
      isShareOpen,
      shouldOpenFullscreenModal,
      channel,
      mainVideoId,
      closeShareOverlay,
      showPlayerUI,
    }) {
      if (shouldOpenFullscreenModal && isShareOpen && !this.props.isShareOpen) {
        pauseVideo();
        closeShareOverlay();
        showPlayerUI();
        this.openFullScreenShareOverlay(channel.id, mainVideoId);
      }
    }

    openFullScreenShareOverlay = notForPreview((channelId, mainVideoId) => {
      this.props.openFullScreenShareOverlay(channelId, mainVideoId);
    });

    render() {
      return null;
    }
  },
);
