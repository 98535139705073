import _ from 'lodash';
import { createSelector } from 'reselect';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { shouldShowChannelCover } from '@wix/wix-vod-shared/dist/src/common/utils/overlay-buttons-utils';
import {
  getAllSettings,
  canShowMainItemTitle,
  canShowChannelTitle,
  isSliderLayout,
  isGridLayout,
  canShowVideoListItemTitle,
  isVerticalLayoutMobile,
} from '../../../../selectors/app-settings';

const getVideo = (state, props) => props.videoItem;
const isVideosTouched = (state) => state.isVideosTouched;
const getVideoId = (state, props) => _.get(props, 'videoItem.id');
const getVideoTitle = (state, props) => _.get(props, 'videoItem.title');
const getChannelTitle = createSelector(
  [getChannelForWidget],
  (channel) => channel.title,
);
const isFirstVideo = (state, props) => props.isFirstVideo;

const isInitialVideo = createSelector(
  [getChannelForWidget, getVideoId, isFirstVideo],
  (channelData, videoId, isFirstVideo) =>
    _.get(channelData, 'featuredItem') === videoId || isFirstVideo,
);

const isInitialCover = createSelector(
  [isVideosTouched, isInitialVideo, isVerticalLayoutMobile],
  (isVideosTouched, isInitialVideo, isVerticalLayoutMobile) =>
    !isVerticalLayoutMobile && !isVideosTouched && isInitialVideo,
);

export const isChannelCoverView = createSelector(
  [getAllSettings, isInitialCover, getVideo],
  (appSettings, isInitialCover, videoItem) =>
    !videoItem || shouldShowChannelCover(appSettings, isInitialCover),
);

const isThumbnailTitleSelector = createSelector(
  isChannelCoverView,
  isGridLayout,
  isSliderLayout,
  (isCover, isGrid, isSlider) => isCover && (isGrid || isSlider),
);

const mainTitleSelector = createSelector(
  isChannelCoverView,
  canShowChannelTitle,
  getChannelTitle,
  getVideoTitle,
  (isChannelCoverView, canShowChannelTitle, channelTitle, videoTitle) =>
    isChannelCoverView && canShowChannelTitle ? channelTitle : videoTitle,
);

const thumbnailTitleSelector = createSelector(
  isChannelCoverView,
  canShowChannelTitle,
  canShowVideoListItemTitle,
  getChannelTitle,
  getVideoTitle,
  (
    isChannelCoverView,
    canShowChannelTitle,
    canShowVideoListItemTitle,
    channelTitle,
    videoTitle,
  ) => {
    if (canShowChannelTitle) {
      return channelTitle;
    }

    // Grid and slider layouts don't have a channel/featured video cover toggler by itself.
    // But this parameter can be changed from settings of another layout.
    // In such case we should just not show video title for channel cover
    if (canShowVideoListItemTitle && !isChannelCoverView) {
      return videoTitle;
    }

    return '';
  },
);

export const getTitle = createSelector(
  canShowMainItemTitle,
  isThumbnailTitleSelector,
  thumbnailTitleSelector,
  mainTitleSelector,
  (canShowMainItemTitle, isThumbnailTitle, thumbnailTitle, mainTitle) => {
    if (!canShowMainItemTitle && !isThumbnailTitle) {
      return '';
    }

    return isThumbnailTitle ? thumbnailTitle : mainTitle;
  },
);

export const getCover = createSelector(
  [getChannelForWidget, getVideo, isChannelCoverView],
  (channel, video = {}, isChannelCoverView) => {
    const { customCoverUrl, coverUrl } = video;
    const videoUrl = customCoverUrl || coverUrl;
    return isChannelCoverView
      ? channel.customCoverUrl || channel.coverUrl || videoUrl
      : videoUrl;
  },
);
