"use strict";

exports.__esModule = true;
exports.default = void 0;
var _default = {
  TOP_LEFT: 'anchor-top-left',
  TOP_RIGHT: 'anchor-top-right',
  BOTTOM_LEFT: 'anchor-bottom-left',
  BOTTOM_RIGHT: 'anchor-bottom-right'
};
exports.default = _default;