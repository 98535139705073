import { createAction } from 'redux-actions';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getAllSettings } from '../../../selectors/app-settings';
import { canShowChannelCover } from '../../../components/player-overlay/selectors';
import { notForPreview } from '../../../utils/not-for-preview';
import {
  openFullScreenPurchaseOverlay,
  openFullScreenRentOverlay,
  openFullScreenSubscribeOverlay,
} from '../../../utils/open-overlay';
import { logPurchaseButtonClicked } from '../../../components/player-overlay/bi';
import { createProxy } from '../../../worker/lib';

import {
  getFullAccessActionName,
  FULL_ACCESS_ACTION_NAMES,
} from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';

const OPEN_FULL_SCREEN_OVERLAY =
  'CLIENT.PLAYER_OVERLAY.OPEN_FULL_SCREEN_OVERLAY';
const openOverlayAction = createAction(OPEN_FULL_SCREEN_OVERLAY);

const openOverlay =
  (fedops, { channelData, videoItem }, actionName) =>
  (dispatch) => {
    switch (actionName) {
      case FULL_ACCESS_ACTION_NAMES.SUBSCRIBE:
        return dispatch(openFullScreenSubscribeOverlay(fedops, channelData.id));
      case FULL_ACCESS_ACTION_NAMES.RENT:
        return dispatch(
          openFullScreenRentOverlay(fedops, channelData.id, videoItem.id),
        );
      default:
        return dispatch(
          openFullScreenPurchaseOverlay(fedops, channelData.id, videoItem.id),
        );
    }
  };

export const openPlayerOverlay = createProxy(
  'openPlayerOverlay',
  (fedops, videoItem, showChannelCover) => (dispatch, getState) => {
    const state = getState();
    const channelData = getChannelForWidget(state);
    const appSettings = getAllSettings(state);
    const isChannelCover = canShowChannelCover(state, { showChannelCover });
    const actionName = getFullAccessActionName(channelData, videoItem);
    const props = {
      channelData,
      appSettings,
      videoItem,
      isChannelCover,
    };

    notForPreview(() => {
      dispatch(logPurchaseButtonClicked(props));
      dispatch(openOverlay(fedops, props, actionName));
    })();

    dispatch(openOverlayAction());
  },
);
