import _ from 'lodash';
import { dashify } from '@wix/wix-vod-shared/dist/src/common/utils/guid';
import { APP_SETTINGS_VERSION_LATEST } from '@wix/wix-vod-constants/dist/app-settings/versions';
import { isDemoChannel } from './channel-helpers';
import { isGraphAPIEnabled } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';

const createIdGetter = (key) => (source) => {
  const value = _.get(source, key);
  const id = value ? value.replace(/'/g, '') : value;
  return isGraphAPIEnabled() ? dashify(id) : id;
};

export const getChannelId = createIdGetter('fonts.channelId');

export const getVideoId = createIdGetter('fonts.videoId');

export const isLatestVersion = (appSettings) =>
  _.get(appSettings, 'numbers.settingsVersion') === APP_SETTINGS_VERSION_LATEST;

export const isNewInstallation = (appSettings) => {
  const channelId = getChannelId(appSettings);
  return !channelId || isDemoChannel(channelId);
};
