import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getInstance } from '../../redux/hydrated-data/hydrated-data';
import VideoPreview from './video-preview/video-preview';
import { Picture } from '@wix/wix-vod-shared/dist/src/common/components/picture';
import * as viewModeSelectors from '../../selectors/view-mode';
import { isPortableDevice } from '../../selectors/form-factor';
import {
  MAX_COVER_IMAGE_WIDTH,
  MAX_COVER_IMAGE_HEIGHT,
} from '../../constants/sizes';

const mapStateToProps = (state) => ({
  instance: getInstance(state),
  isEditorViewMode: viewModeSelectors.isEditorMode(state),
  isMobileDevice: isPortableDevice(state),
});

const ChannelCover = connect(mapStateToProps)(
  class ChannelCover extends React.Component {
    static propTypes = {
      channelId: PropTypes.string,
      channelTitle: PropTypes.string,
      videoTitle: PropTypes.string,
      instance: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
      posterUrl: PropTypes.string,
      mediaInfo: PropTypes.object,
      isEditorViewMode: PropTypes.bool,
      isMobileDevice: PropTypes.bool,
      showChannelCover: PropTypes.bool,
    };

    shouldRenderVideoPreview() {
      const { isEditorViewMode, isMobileDevice } = this.props;

      return this.isVideoPreview() && !isEditorViewMode && !isMobileDevice;
    }

    isVideoPreview() {
      const { showChannelCover, mediaInfo } = this.props;
      return Boolean(showChannelCover && mediaInfo);
    }

    getImageCover() {
      const {
        width = MAX_COVER_IMAGE_WIDTH,
        height = MAX_COVER_IMAGE_HEIGHT,
        posterUrl,
        channelTitle,
        videoTitle,
        showChannelCover,
      } = this.props;

      return (
        <Picture
          dataHook="image-cover"
          alt={showChannelCover ? channelTitle : videoTitle}
          key={posterUrl}
          src={posterUrl}
          breakpoints={[
            {
              min: 0,
              width,
              height,
            },
          ]}
        />
      );
    }

    render() {
      const { width, height, channelId, instance } = this.props;

      if (this.shouldRenderVideoPreview()) {
        return (
          <VideoPreview
            dataHook="video-preview"
            channelId={channelId}
            instance={instance}
            poster={this.getImageCover()}
            width={width}
            height={height}
          />
        );
      }

      return this.getImageCover();
    }
  },
);

export default ChannelCover;
