import { logBi } from '../../../../worker/actions/bi';

import { getAllSettings } from '../../../../selectors/app-settings';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getMainVideoId } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/selected-video-id';
import { getVideosGroupedByIds } from '../../../../selectors/videos';
import { getVideoSelectedEventParams } from '../../../../utils/bi/video-overlay-events-params';

import { SELECTED_VIDEO_ACTIONS } from '../../../actions/select-video';

export default {
  [SELECTED_VIDEO_ACTIONS.SET]: ({
    dispatch,
    state,
    action,
    previousState,
  }) => {
    if (action.payload === previousState.selectedVideoId) {
      return;
    }

    dispatch(
      logBi(
        'widget.VidSelected.displayed',
        getVideoSelectedEventParams({
          appSettings: getAllSettings(state),
          channelData: getChannelForWidget(state),
          videoItem: getVideosGroupedByIds(state)[getMainVideoId(state)],
        }),
      ),
    );
  },
};
