import React from 'react';
import ClampText from '@wix/wix-vod-shared/dist/src/common/components/clamp-text/clamp-text';
import PropTypes from 'prop-types';

const defaultClampOptions = {
  lineClamp: 2,
};

const Title = ({ text, clampOptions = {}, enforceJSClamp }) => (
  <ClampText
    text={text}
    dataHook="title"
    wrapperTag="h3"
    enforceJSClamp={enforceJSClamp}
    clampOptions={{ ...defaultClampOptions, ...clampOptions }}
  />
);

Title.propTypes = {
  text: PropTypes.string,
  clampOptions: PropTypes.object,
  enforceJSClamp: PropTypes.bool,
};

export default Title;
