import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { CurrencySign } from '@wix/wix-vod-shared/dist/src/common/components/currency-sign';
import { CircleButton } from '@wix/wix-vod-shared/dist/src/common/components/circle-button';
import styles from '../overlay.scss';

export default class OverlayPaidAccessButton extends React.Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,

    children: PropTypes.node,
    isFocusable: PropTypes.bool,
    isIconOnly: PropTypes.bool,

    className: PropTypes.string,
    iconClassName: PropTypes.string,
    currency: PropTypes.string,

    ariaHidden: PropTypes.bool,
  };

  static defaultProps = {
    isFocusable: true,
    isIconOnly: false,
    ariaHidden: false,
  };

  handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    this.props.onClick();
  };

  render() {
    const {
      children,
      isIconOnly,
      isFocusable,
      className,
      ariaHidden,
      currency,
    } = this.props;

    const classNames = classnames(styles.button, className, {
      [styles['only-icon']]: isIconOnly,
    });

    return (
      <a
        href="#"
        className={classNames}
        onClick={this.handleClick}
        data-hook="paid-access-button"
        aria-hidden={ariaHidden}
        tabIndex={isFocusable ? 0 : -1}
      >
        <div className={styles.icon}>
          <CircleButton borderWidth={isIconOnly ? 3 : 2}>
            <CurrencySign currency={currency} />
          </CircleButton>
        </div>
        {children && <span className={styles.text}>{children}</span>}
      </a>
    );
  }
}
