import Lodash from 'lodash';
import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import biMiddleware from './bi/bi-middleware';
import lazyChannelVideosMiddleware from './redux/middleware/lazy-channel-videos/lazy-channel-videos-middleware';
import biHandlers from './redux/middleware/bi-middleware/bi-handlers';
import { initNotForPreview } from './utils/not-for-preview';

export const createConfigureStore =
  ({ createRootReducer, createRootReducerPath }) =>
  ({
    initialState = {},
    middlewares: additionalMiddlewares = [],
    history,
    handlers,
  }) => {
    const storage = {};
    const middlewares = [
      thunk.withExtraArgument({ storage, handlers }),
      routerMiddleware(history),
      lazyChannelVideosMiddleware,
      biMiddleware(biHandlers),
    ];

    middlewares.push(...additionalMiddlewares);

    const composeEnhancers =
      typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose;

    const middleware = composeEnhancers(applyMiddleware(...middlewares));

    // Create final store and subscribe router in debug env ie. for devtools
    const store = middleware(createStore)(
      createRootReducer({ history }),
      Lodash.omit(initialState, 'router'),
    );

    if (module.hot) {
      module.hot.accept(createRootReducerPath, () => {
        const createNextRootReducer = require(createRootReducerPath).default;
        store.replaceReducer(createNextRootReducer({ history }));
      });
    }

    initNotForPreview(store);

    return store;
  };
