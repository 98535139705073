import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from '@wix/yoshi-flow-editor';
import { getVideosGroupedByIds } from '../../../../selectors/videos';
import { getAllSettings, isRTL } from '../../../../selectors/app-settings';
import { getCompId } from '../../../../redux/hydrated-data/hydrated-data';
import { logWidgetSystem } from '../../../../worker/actions/bi';

import ContentSlider from '@wix/wix-vod-shared/dist/src/widget/ui-components/content-slider/content-slider';
import PageList from '@wix/wix-vod-shared/dist/src/widget/ui-components/page-list/page-list';
import Nav from '@wix/wix-vod-shared/dist/src/widget/ui-components/nav/nav';
import VideoListItem from './_video-list-item/video-list-item';

import styles from './videos.scss';
import { MAX_WIDGET_WIDTH } from '../../../../utils/videos-sizes/videos-sizes';

const mapStateToProps = (state) => ({
  videoByIds: getVideosGroupedByIds(state),
  appSettings: getAllSettings(state),
  isRTL: isRTL(state),
  styleId: getCompId(state),
});

const mapDispatchToProps = { logWidgetSystem };

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class Videos extends React.Component {
      static propTypes = {
        appSettings: PropTypes.object,
        channelData: PropTypes.object,
        currentSiteUser: PropTypes.object,

        containerWidth: PropTypes.number,

        videoByIds: PropTypes.object,
        videoIdsByPageNumber: PropTypes.array,

        thumbnailSize: PropTypes.shape({
          width: PropTypes.number,
          height: PropTypes.number,
        }),

        onSlideToPrev: PropTypes.func,
        onSlideToNext: PropTypes.func,
        onPlayRequest: PropTypes.func,
        onThumbnailClick: PropTypes.func,

        numberOfRows: PropTypes.number,
        numberOfColumns: PropTypes.number,

        currentVideosPageNumber: PropTypes.number,
        isRTL: PropTypes.bool,
        styleId: PropTypes.string,
      };

      componentDidMount() {
        this.props.logWidgetSystem('videoList.show.rendered', {
          previousEventName: 'videoList.show.requested',
        });
      }

      UNSAFE_componentWillReceiveProps({ currentVideosPageNumber }) {
        if (currentVideosPageNumber !== this.props.currentVideosPageNumber) {
          this.props.logWidgetSystem('videoList.changePage.rendered', {
            previousEventName: 'videoList.changePage.requested',
          });
        }
      }

      componentDidUpdate() {
        this.props.logWidgetSystem('videoList.searchByQuery.rendered', {
          previousEventName: 'videoList.searchByQuery.requested',
        });

        this.props.logWidgetSystem('videoList.searchByTag.rendered', {
          previousEventName: 'videoList.searchByTag.requested',
        });

        this.props.logWidgetSystem('videoList.searchByCategory.rendered', {
          previousEventName: 'videoList.searchByCategory.requested',
        });
      }

      renderVideoItem = (videoId, videoAtPageIndex, isVisiblePage) => {
        const {
          channelData,
          videoByIds,
          thumbnailSize,
          appSettings,
          onThumbnailClick,
          onPlayRequest,
          currentSiteUser,
        } = this.props;

        return (
          <VideoListItem
            {...thumbnailSize}
            channelData={channelData}
            videoItem={videoByIds[videoId]}
            appSettings={appSettings}
            currentSiteUser={currentSiteUser}
            key={videoId}
            isFocusable={isVisiblePage}
            onClick={onThumbnailClick}
            onPlayRequest={onPlayRequest}
          />
        );
      };

      hasPage(pageNum) {
        const { videoIdsByPageNumber } = this.props;
        return Boolean(videoIdsByPageNumber[pageNum]);
      }

      get isPrevPageVisible() {
        const { currentVideosPageNumber } = this.props;
        return this.hasPage(currentVideosPageNumber - 1);
      }

      get isNextPageVisible() {
        const { currentVideosPageNumber } = this.props;
        return this.hasPage(currentVideosPageNumber + 1);
      }

      render() {
        const {
          currentVideosPageNumber,
          videoIdsByPageNumber,
          containerWidth,
          onSlideToPrev,
          onSlideToNext,
          isRTL,
          numberOfRows,
          numberOfColumns,
          styleId,
        } = this.props;

        return (
          <div className={styles.container}>
            <ContentSlider
              width={containerWidth}
              currentPageNumber={currentVideosPageNumber}
              isRTL={isRTL}
            >
              <PageList
                gridId="strip-videos"
                styleId={styleId}
                pageWidth="100%"
                gridMinWidth={MAX_WIDGET_WIDTH}
                currentPageNumber={currentVideosPageNumber}
                itemsByPageNumber={videoIdsByPageNumber}
                renderItem={this.renderVideoItem}
                numberOfRows={numberOfRows}
                numberOfColumns={numberOfColumns}
                isRTL={isRTL}
              />
            </ContentSlider>
            <Nav
              prevButtonClassName={styles['prev-button']}
              nextButtonClassName={styles['next-button']}
              prevButtonAriaLabel={this.props.t(
                'widget.accessibility.prev-videos',
              )}
              nextButtonAriaLabel={this.props.t(
                'widget.accessibility.next-videos',
              )}
              onPrevClick={onSlideToPrev}
              onNextClick={onSlideToNext}
              isPrevVisible={this.isPrevPageVisible}
              isNextVisible={this.isNextPageVisible}
              isRTL={isRTL}
            />
          </div>
        );
      }
    },
  ),
);
