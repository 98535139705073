import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dropdown from '@wix/wix-vod-shared/dist/src/widget/ui-components/dropdown/dropdown';

import { updateSearch } from '../../../redux/actions/search';
import { getCategory } from '../../../selectors/search';
import { getChannelCategoryOptions } from '../../../selectors/categories';
import { resetPlaybackStatus } from '../../../redux/actions/player/change-playback-status';
import { isActionBarPreview, isRTL } from '../../../selectors/app-settings';

import { SEARCH_QUERY_FIELDS } from '../../../constants/search-query-fields';
import { logBi, logWidgetSystem } from '../../../worker/actions/bi';

import { withTranslation } from '@wix/yoshi-flow-editor';
import styles from './categories-dropdown.scss';
import { t } from '../../../utils/translation';

const mapStateToProps = (state) => ({
  isOpen: isActionBarPreview(state),
  categoryOptions: [
    {
      label: t('widget.categories.all-videos'),
      value: '',
    },
    ...getChannelCategoryOptions(state),
  ],
  selectedCategory: getCategory(state),
  isRTL: isRTL(state),
});

const mapDispatchToProps = {
  updateSearch,
  resetPlaybackStatus,
  logBi,
  logWidgetSystem,
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class CategoriesDropdown extends React.Component {
      static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        className: PropTypes.string,
        selectedCategory: PropTypes.string,
        updateSearch: PropTypes.func.isRequired,
        resetPlaybackStatus: PropTypes.func.isRequired,
        categoryOptions: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string,
            key: PropTypes.string,
          }),
        ).isRequired,
      };

      getOptionByValue = (value) =>
        _.find(this.props.categoryOptions, { value });

      handleChange = (option) => {
        const { updateSearch, selectedCategory, resetPlaybackStatus } =
          this.props;

        if (selectedCategory !== option.value) {
          updateSearch({
            searchQuery: option.value,
            searchQueryField: SEARCH_QUERY_FIELDS.CATEGORY,
          });

          resetPlaybackStatus();
        }

        this.props.logWidgetSystem('videoList.searchByCategory.requested');
        this.props.logBi('widget.categories.selected');
      };

      handleDropdownToggle = (isOpen) => {
        if (isOpen) {
          this.props.logBi('widget.categories.clicked');
        }
      };

      render() {
        const { className, selectedCategory, categoryOptions, isOpen, isRTL } =
          this.props;

        return (
          <div className={className} data-hook="categories-dropdown">
            <Dropdown
              options={categoryOptions}
              isOpen={isOpen}
              openerClassName={styles.dropdownContainer}
              valueClassName={classnames(styles.value, {
                [styles.pristine]: !selectedCategory,
              })}
              ariaLabel={this.props.t('widget.accessibility.select-category')}
              arrowClassName={styles.arrow}
              optionClassName={styles.option}
              optionsContainerClassName={styles.popoverMenuContainer}
              popoverClassName={styles.popoverMenu}
              onChange={this.handleChange}
              selectedOption={this.getOptionByValue(selectedCategory)}
              onToggle={this.handleDropdownToggle}
              isRTL={isRTL}
            />
          </div>
        );
      }
    },
  ),
);
