import {
  getFullAccessActionName,
  FULL_ACCESS_ACTION_NAMES,
} from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';

const getBuyClickHandler = ({
  purchaseFn,
  subscribeFn,
  videoItem,
  channelData,
}) => {
  const status = getFullAccessActionName(channelData, videoItem);

  if (
    status === FULL_ACCESS_ACTION_NAMES.SUBSCRIBE ||
    status === FULL_ACCESS_ACTION_NAMES.PRICING_PLAN
  ) {
    return subscribeFn;
  } else if (status) {
    return purchaseFn;
  }
};

export default getBuyClickHandler;
