import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { VideoCardType } from '../../redux/types';
import { PRODUCTION_HOSTS } from '@wix/wix-vod-constants/dist/env/hosts';
import { pauseVideo } from '../../redux/actions/player/change-playback-status';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getVideosGroupedByIds } from '../../selectors/videos';
import { getWidgetVideoCards } from '../../selectors/video-cards';
import videoCardsBiLogger from '../../player-widget/components/new-player/bi-loggers/video-cards-bi-logger';

const { logCardClick, logCardCloseButtonClick } = videoCardsBiLogger;

const isVideoCard = (card) =>
  card.type === VideoCardType.Video || card.type === VideoCardType.RecentVideo;
const isCustomContentCard = (card) =>
  card.type === VideoCardType.Product || card.type === VideoCardType.Custom;
const DEFAULT_THUMBNAIL_SIZE = 110;

const mapStateToProps = (state, { thumbnailSize }) => ({
  cardsData: getWidgetVideoCards({
    thumbnailSize,
    imageHost: PRODUCTION_HOSTS.image,
  })(state),
  videoByIds: getVideosGroupedByIds(state),
  channelData: getChannelForWidget(state),
});

const mapDispatchToProps = {
  pauseVideo,
  logCardClick,
  logCardCloseButtonClick,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class VideoCardsWrapper extends React.PureComponent {
    static propTypes = {
      handlePlayRequest: PropTypes.func.isRequired,

      channelData: PropTypes.object,
      videoByIds: PropTypes.object,
      cardsData: PropTypes.array,

      player: PropTypes.object,

      children: PropTypes.func.isRequired,
      pauseVideo: PropTypes.func,

      thumbnailSize: PropTypes.number,

      biOrigin: PropTypes.string,
    };

    static defaultProps = {
      thumbnailSize: DEFAULT_THUMBNAIL_SIZE,
    };

    componentDidMount() {
      const { player } = this.props;
      if (player && _.isFunction(player.onCardClose)) {
        player.onCardClose(this.logCardClose);
      }
    }

    logCardClose = ({ id }) => {
      this.props.logCardCloseButtonClick({
        card: _.find(this.props.cardsData, { clientId: id }),
      });
    };

    handleCardClick = (card) => {
      const { channelData, pauseVideo, biOrigin, handlePlayRequest } =
        this.props;
      const { actionListId, actionItemId } = card;

      this.props.logCardClick({ card, biOrigin });

      if (isVideoCard(card) && channelData.id === actionListId) {
        handlePlayRequest({ id: actionItemId });
        return;
      }

      if (isCustomContentCard(card) && !card.url) {
        return;
      }

      pauseVideo();
    };

    render() {
      const { cardsData, children } = this.props;

      return children({
        cardsData,
        onCardClick: this.handleCardClick,
      });
    }
  },
);
