export enum DealInfoType {
  Rent = 'rent',
  Sale = 'sale',
  Subscription = 'subscription',
  PricingPlan = 'pricing_plan',
}

export type DealInfo = {
  price: number;
  enabled: boolean;
  period: number;
  currency: string;
  type: DealInfoType;
};
