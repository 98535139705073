import _ from 'lodash';
import { createSelector } from 'reselect';
import { getCurrentSiteUser } from '../../selectors/current-site-user';

import {
  isMainItemChannelCover,
  canShowMainItemPublisher,
  canShowMainItemTitle,
  getMainItemOverlayOpacity,
} from '../../selectors/app-settings';

import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getMinPriceInfo } from '@wix/wix-vod-shared/dist/src/common/utils/deal-info-helpers';
import { getVideoCoverUrl } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/cover';
import { getChannelCoverUrl } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/channel/cover';

import renderVideoPlayButtonText from '../overlay-texts/play-button-text';
import renderVideoSignInToWatchButtonText from '../overlay-texts/video/sign-in-to-watch-button-text';
import renderVideoSubscribeButtonText from '../overlay-texts/video/subscribe-button-text';
import renderChannelSignInToWatchButtonText from '../overlay-texts/channel/sign-in-to-watch-button-text';
import renderChannelSubscribeButtonText from '../overlay-texts/channel/subscribe-button-text';
import renderOpenVideoWithoutPreviewButtonText from '../overlay-texts/channel/open-video-without-preview-button-text';
import renderPurchaseButtonText from '../overlay-texts/purchase-button-text';
import { t } from '../../utils/translation';

const getIsVideosPristine = (state) => !state.isVideosTouched;

const getShowChannelCover = (state, ownProps) =>
  _.isUndefined(ownProps.showChannelCover) ? true : ownProps.showChannelCover;

const getVideoItem = (state, ownProps) => ownProps.videoItem;

const getEndedProp = (state, ownProps) => ownProps.ended;

export const canShowChannelCover = createSelector(
  getIsVideosPristine,
  isMainItemChannelCover,
  getShowChannelCover,
  (isPristine, isSettingSet, showChannelCover) =>
    isPristine && isSettingSet && showChannelCover,
);

export const getTitleText = createSelector(
  canShowMainItemTitle,
  canShowChannelCover,
  getChannelForWidget,
  getVideoItem,
  (canShowTitle, isChannelCover, channelData, videoItem) => {
    if (!canShowTitle) {
      return '';
    }

    return isChannelCover ? channelData.title : videoItem.title;
  },
);

const getTextRendererProps = createSelector(
  getChannelForWidget,
  getVideoItem,
  getCurrentSiteUser,
  (channel, video, currentSiteUser) => ({
    channel,
    video,
    currentSiteUser,
  }),
);

export const getSubscribeButtonText = createSelector(
  getTextRendererProps,
  canShowChannelCover,
  (props, isChannelCover) =>
    (isChannelCover
      ? renderChannelSubscribeButtonText(props)
      : renderVideoSubscribeButtonText(props)) || '',
);

export const getPublisherText = createSelector(
  canShowMainItemPublisher,
  getVideoItem,
  canShowChannelCover,
  (isSettingOn, videoItem, isChannelCover) => {
    if (!isSettingOn || !videoItem.publisher) {
      return '';
    }

    return isChannelCover ? '' : videoItem.publisher;
  },
);

export const getOpacityStyle = createSelector(
  getMainItemOverlayOpacity,
  (opacityPercents) => ({
    backgroundColor: `rgba(0,0,0,${_.round(opacityPercents / 100, 2)})`,
  }),
);

export const getPlayButtonText = createSelector(
  getTextRendererProps,
  canShowChannelCover,
  (props, isChannelCover) => {
    if (isChannelCover) {
      return (
        renderVideoPlayButtonText(props) ||
        renderChannelSignInToWatchButtonText(props) ||
        renderOpenVideoWithoutPreviewButtonText(props)
      );
    }

    return (
      renderVideoPlayButtonText(props) ||
      renderVideoSignInToWatchButtonText(props)
    );
  },
);

export const getPurchaseButtonText = createSelector(
  getTextRendererProps,
  canShowChannelCover,
  (props, isChannelCover) =>
    isChannelCover ? '' : renderPurchaseButtonText(props),
);

const getMinVideoPriceInfo = createSelector(getVideoItem, (videoItem) =>
  videoItem ? getMinPriceInfo(videoItem.dealInfo) : null,
);

const getNextVideoId = (state, ownProps) => ownProps.nextVideoId;

const getHasNextVideo = createSelector(getNextVideoId, (nextVideoId) =>
  Boolean(nextVideoId),
);

export const getPlayNextButtonText = createSelector(
  getTextRendererProps,
  getEndedProp,
  getMinVideoPriceInfo,
  getHasNextVideo,
  (props, ended, minVideoPriceInfo, hasNextVideo) => {
    if (!ended || minVideoPriceInfo || !hasNextVideo) {
      return null;
    }

    return t('widget.overlay-buttons.next-video');
  },
);

export const getCoverUrl = createSelector(
  canShowChannelCover,
  getChannelForWidget,
  getVideoItem,
  (isChannelCover, channel, video) => {
    const channelCover = getChannelCoverUrl(channel);
    const videoCover = getVideoCoverUrl(video);

    return isChannelCover ? channelCover || videoCover : videoCover;
  },
);
