import { isVODChannel } from '../../../../utils/channel-helpers';
import {
  createAsyncActions,
  AsyncAction,
} from '../../../helpers/create-async-actions';
import { getChannelById } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getHydratedData } from '../../../hydrated-data/hydrated-data';
import { isGraphAPIEnabled } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';
import { publicApiV3 } from '../../../../api/v3/public';
import { getPublicApi } from '../../../../api/public';
import { ActionCreator } from '../../../redux.types';

type GetPublicVideoParams = {
  channelId: string;
  videoId: string;
};
type GetPublicVideoResponse = any;
export type GetPublicVideoAction = AsyncAction<
  GetPublicVideoResponse,
  GetPublicVideoParams
>;

const ACTIONS = createAsyncActions<
  GetPublicVideoResponse,
  GetPublicVideoParams
>('SERVER.CHANNEL.VIDEO.GET');
export const GET_PUBLIC_VIDEO_ACTIONS = ACTIONS.NAMES;
const { START, SUCCESS, FAIL } = ACTIONS;

const getPublicVideoGql: ActionCreator =
  (channelId: string, videoId: string) => async (dispatch, getState) => {
    const params: GetPublicVideoParams = { channelId, videoId };
    const state = getState();
    dispatch(START(params));

    const {
      singleVideoId: hydratedSingleVideoId,
      singleVideo: hydratedSingleVideo,
    } = getHydratedData(state);

    let promise;
    if (videoId === hydratedSingleVideoId && hydratedSingleVideo) {
      promise = Promise.resolve(hydratedSingleVideo);
    } else {
      promise = publicApiV3.channel.getVideo(videoId);
    }

    try {
      const video = await promise;
      dispatch(SUCCESS(params, video));
    } catch (error) {
      dispatch(
        FAIL(params, error, {
          analytics: { type: 'error', name: 'shared.channel.video.get' },
        }),
      );
    }
  };

export const getPublicVideo: ActionCreator =
  (channelId, videoId) => async (dispatch, getState) => {
    const state = getState();
    if (isGraphAPIEnabled()) {
      return dispatch(getPublicVideoGql(channelId, videoId));
    }
    const params = { channelId, videoId };
    const channel = getChannelById(state, channelId);
    const { singleVideoId, singleVideo, templateMetaSiteId } =
      getHydratedData(state);

    dispatch(START(params));

    let response;
    const publicApi = getPublicApi();
    try {
      if (singleVideoId === videoId) {
        response = await publicApi.channelVideos.getChannelVideoById(
          channelId,
          videoId,
          {
            hydratedVideo: singleVideo,
            templateMetaSiteId,
          },
        );
      } else if (channel) {
        response = isVODChannel(channel)
          ? await publicApi.channelVideos.getChannelVideoById(
              channelId,
              videoId,
              {
                templateMetaSiteId,
              },
            )
          : await publicApi.youtubeVideosService.getYoutubeVideoById(videoId);
      } else {
        try {
          response = await publicApi.channelVideos.getChannelVideoById(
            channelId,
            videoId,
            {
              templateMetaSiteId,
            },
          );
        } catch (error: any) {
          if (error.status === 404) {
            response = await publicApi.youtubeVideosService.getYoutubeVideoById(
              videoId,
            );
          }
        }
      }

      dispatch(SUCCESS(params, response?.data));
      return response;
    } catch (error) {
      dispatch(
        FAIL(params, error, {
          analytics: { type: 'error', name: 'shared.channel.video.get' },
        }),
      );
    }
  };
