import { isVerticalLayoutMobile } from '../selectors/app-settings';
import { getVideoIds } from '../redux/lazy-channel-videos/selectors';
import { clearHydratedData } from '../redux/hydrated-data/hydrated-data';
import { getPublicChannel } from '../redux/actions/channel/get-public';
import { getPaymentInfo } from '../redux/actions/channel/payment';
import { getMainVideoId } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/selected-video-id';
import { getVideoId } from '../utils/app-settings';
import {
  loadMoreVideoPages,
  prependVideoIds,
} from '../redux/lazy-channel-videos/actions';
import { getPublicVideo } from '../redux/actions/channel/video/get';
import { selectVideo } from '../redux/actions/select-video';
import _ from 'lodash';
import { getVideoIdFromUrl } from '../selectors/query-params';
import { getVideoById } from '../selectors/videos';
import { openVideoPage } from '../redux/actions/mobile-overlay-openners/main-openners';
import { getCurrentChannelId } from '../selectors/channel-info';

export const setInitialState = () => async (dispatch, getState) => {
  const state = getState();
  const channelId = getCurrentChannelId(state);
  const singleVideoId = getVideoId(state.appSettings);
  const videoIdFromUrl = getVideoIdFromUrl(state);

  await loadInitialNeededData(dispatch, channelId, videoIdFromUrl);

  if (singleVideoId) {
    await loadDataForSingleVideo(dispatch, channelId, singleVideoId);
  } else {
    await loadPageVideos(dispatch, state, getState);
  }

  dispatch(clearHydratedData());
};

export const openVideoFromUrlIfExists = () => (dispatch, getState) => {
  const state = getState();
  const videoIdFromUrl = getVideoIdFromUrl(state);
  const videoFromUrlExists = getVideoById(state, videoIdFromUrl);
  if (videoFromUrlExists) {
    dispatch(openVideoPage(videoIdFromUrl));
  }
};

const loadDataForSingleVideo = async (dispatch, channelId, singleVideoId) => {
  await dispatch(getPublicVideo(channelId, singleVideoId));
  dispatch(prependVideoIds(singleVideoId));
  dispatch(selectVideo(singleVideoId));
};

const loadInitialNeededData = async (dispatch, channelId, videoIdFromUrl) => {
  const initDataToBeLoaded = [
    dispatch(getPublicChannel(channelId)),
    dispatch(getPaymentInfo(channelId)),
  ];

  if (videoIdFromUrl) {
    initDataToBeLoaded.push(
      dispatch(getPublicVideo(channelId, videoIdFromUrl)),
    );
  }

  return Promise.all(initDataToBeLoaded);
};

const loadPageVideos = async (dispatch, state, getState) => {
  const pagesCount = isVerticalLayoutMobile(state) ? 2 : undefined;
  await dispatch(loadMoreVideoPages(pagesCount));

  const videoIds = getVideoIds(getState());
  const mainVideoId = getMainVideoId(getState());

  if (!_.includes(videoIds, mainVideoId)) {
    dispatch(prependVideoIds(mainVideoId));
  }
};
