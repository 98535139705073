import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MobileCarousel from './mobile-carousel/mobile-carousel';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import firstTimeTrackAnimation from './first-time-track-animation/first-time-track-animation';
import { getVideosGroupedByIds } from '../../../../selectors/videos';
import { isRTL } from '../../../../selectors/app-settings';
import { getCurrentSiteUser } from '../../../../selectors/current-site-user';
import { shouldFetchUrls } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/fetch-urls';
import { getVideoIds } from '../../../../redux/lazy-channel-videos/selectors';
import { fetchVideoUrls } from '../../../../redux/actions/video-urls/fetch';

const mapStateToProps = (state) => ({
  videoIds: getVideoIds(state),
  videoByIds: getVideosGroupedByIds(state),
  currentSiteUser: getCurrentSiteUser(state),
  channelData: getChannelForWidget(state),
  windowSize: state.windowSize,
  isRTL: isRTL(state),
});

const mapDispatchToProps = {
  fetchVideoUrls,
};

export default firstTimeTrackAnimation(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class SlidableListWithLazyLoad extends React.Component {
      static propTypes = {
        videoIds: PropTypes.array,
        videoByIds: PropTypes.object,
        channelData: PropTypes.object,
        currentSiteUser: PropTypes.object,

        initialVideoId: PropTypes.string,
        className: PropTypes.string,
        trackClassName: PropTypes.string,
        itemHeight: PropTypes.number,
        windowSize: PropTypes.object,

        onUserStartedTracking: PropTypes.func,
        onCurrentSlideChanged: PropTypes.func,
        fetchVideoUrls: PropTypes.func,
        beforeCurrentSlideChanged: PropTypes.func,
        isRTL: PropTypes.bool,

        children: PropTypes.arrayOf(PropTypes.element),
      };

      static defaultProps = {
        onCurrentSlideChanged: _.noop,
      };

      UNSAFE_componentWillReceiveProps(nextProps) {
        this.preloadUrls(nextProps.videoByIds);
      }

      preloadUrls(videos) {
        _.forEach(videos, (videoItem) => this.fetchUrlsForVideo(videoItem));
      }

      fetchUrlsForVideo(videoItem) {
        const { fetchVideoUrls, currentSiteUser, channelData } = this.props;

        if (shouldFetchUrls({ channelData, videoItem, currentSiteUser })) {
          fetchVideoUrls(videoItem);
        }
      }

      getInitialIndex() {
        const { videoIds, initialVideoId } = this.props;

        const selectedVideoIndex = _.indexOf(videoIds, initialVideoId);
        return selectedVideoIndex < 0 ? 0 : selectedVideoIndex;
      }

      afterChange = (leftVisibleSlideIndex) => {
        this.props.onCurrentSlideChanged(leftVisibleSlideIndex);
      };

      render() {
        const {
          className,
          itemHeight,
          trackClassName,
          onUserStartedTracking,
          children,
          windowSize,
          isRTL,
        } = this.props;

        return (
          <MobileCarousel
            width={windowSize.width}
            afterChange={this.afterChange}
            beforeChange={this.props.beforeCurrentSlideChanged}
            getHeight={_.constant(itemHeight)}
            onUserStartedTracking={onUserStartedTracking}
            slidesToShow={1}
            trackClassName={trackClassName}
            className={className}
            initialSlide={this.getInitialIndex()}
            isRTL={isRTL}
          >
            {children}
          </MobileCarousel>
        );
      }
    },
  ),
);
