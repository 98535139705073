import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from '@wix/yoshi-flow-editor';
import styles from './not-available-overlay.scss';

const NotAvailableOverlay = ({ width, height, t }) => {
  const style = { width, height };

  return (
    <div
      data-hook="video-not-avalilable-overlay"
      className={styles.overlay}
      style={style}
    >
      <div className={styles.title}>{t('widget.video-is-not-available')}</div>
      <div className={styles.text}>
        {this.props.t('widget.check-again-later')}
      </div>
    </div>
  );
};

NotAvailableOverlay.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default withTranslation()(NotAvailableOverlay);
