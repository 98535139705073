import { PrivateSettings } from '@wix/wix-vod-gql-api/dist/src/private/settings/settings';
import { createClient } from '@wix/wix-vod-gql-api/dist/src/client/client';
import { PRIVATE_API_URL } from '@wix/wix-vod-gql-api/dist/src/constants';
import { getWixSDK } from '../../../utils/wix-sdk';

export async function getSettings() {
  const privateClient = createClient(PRIVATE_API_URL, {
    get headers() {
      return {
        Authorization: getWixSDK().Utils.getInstance(),
      };
    },
  });

  const privateSettings = new PrivateSettings(privateClient);
  return privateSettings.get();
}
