import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import memoizedPartial from '@wix/wix-vod-shared/dist/src/common/utils/memoized-partial';
import { onEscPress } from '../../utils/call-on-keyboard-press';
import {
  sides,
  popoutPositions,
  trianglePositions,
} from '../../constants/popout';

import {
  sideShape,
  popoutPositionShape,
  trianglePositionShape,
} from '../../shapes/popout';

import { isPortableDevice } from '../../selectors/form-factor';
import { getCompId } from '../../redux/hydrated-data/hydrated-data';

import Icon from '../icon/icon';
import Popout from '../popout/popout';
import { showTooltip, hideTooltip } from './tooltip-popout';

import styles from './tooltip.scss';

const mapStateToProps = (state) => ({
  isPortableDevice: isPortableDevice(state),
  compId: getCompId(state),
});

export default connect(mapStateToProps)(
  class Tooltip extends React.Component {
    static propTypes = {
      iconName: PropTypes.string,
      dataHook: PropTypes.string,
      preventHideOnHover: PropTypes.bool,
      noTooltip: PropTypes.bool,
      popoutComponent: PropTypes.func,
      popoutSide: sideShape,
      popoutPosition: popoutPositionShape,
      trianglePosition: trianglePositionShape,
      className: PropTypes.string,
      popoutClassName: PropTypes.string,
      popoutTriangleClassName: PropTypes.string,
      popoutInnerClassName: PropTypes.string,
      iconAriaLabel: PropTypes.string,
      getRef: PropTypes.func,
      onTooltipShow: PropTypes.func,
      text: PropTypes.any,
      children: PropTypes.any,
      isButtonFocusable: PropTypes.bool,
      compId: PropTypes.string.isRequired,
    };

    static defaultProps = {
      iconName: 'info',
      isButtonFocusable: true,
      preventHideOnHover: false,
      noTooltip: false,
      popoutComponent: Popout,
      popoutSide: sides.RIGHT,
      popoutPosition: popoutPositions.CENTER,
      trianglePosition: trianglePositions.CENTER,
      getRef: _.noop,
      onTooltipShow: _.noop,
    };

    componentDidMount() {
      document.addEventListener(
        'keyup',
        memoizedPartial(onEscPress, this.hideTooltipPopout),
      );
    }

    UNSAFE_componentWillUpdate(nextProps) {
      const { noTooltip } = nextProps;
      if (noTooltip !== this.props.noTooltip) {
        if (noTooltip) {
          this.hideTooltipPopout();
        } else {
          this.showTooltipPopout();
        }
      }
    }

    componentWillUnmount() {
      this.hideTooltipPopout();
      document.removeEventListener(
        'keyup',
        memoizedPartial(onEscPress, this.hideTooltipPopout),
      );
    }

    showTooltipPopout = () => {
      const {
        popoutSide,
        popoutPosition,
        trianglePosition,
        preventHideOnHover,
        popoutComponent,
        popoutClassName,
        popoutTriangleClassName,
        popoutInnerClassName,
        text,
        noTooltip,
        children,
        onTooltipShow,
        compId,
      } = this.props;

      if (noTooltip) {
        return;
      }

      showTooltip({
        compId,
        preventHideOnHover,
        popoutComponent,

        popoutSide,
        popoutPosition,
        trianglePosition,

        className: popoutClassName,
        innerClassName: popoutInnerClassName,
        triangleClassName: popoutTriangleClassName,

        content: text || children,
        referenceElement: this.icon,

        ...this.tooltipPropsForDevice,
      });

      onTooltipShow();
    };

    hideTooltipPopout() {
      hideTooltip();
    }

    saveRef = (node) => {
      this.icon = node;
      this.props.getRef(node);
    };

    get behaviorProps() {
      if (this.props.isPortableDevice) {
        return {
          onTouchStart: this.showTooltipPopout,
        };
      }

      return {
        onMouseEnter: this.showTooltipPopout,
        onMouseLeave: this.hideTooltipPopout,
      };
    }

    get tooltipPropsForDevice() {
      if (this.props.isPortableDevice) {
        return {
          onClickOutside: this.hideTooltipPopout,
        };
      }

      return {};
    }

    render() {
      const {
        iconName,
        className,
        dataHook,
        iconAriaLabel,
        isButtonFocusable,
      } = this.props;

      return (
        <button
          ref={this.saveRef}
          data-hook={dataHook}
          className={classnames(styles.button, className)}
          onClick={this.showTooltipPopout}
          aria-label={iconAriaLabel}
          tabIndex={isButtonFocusable ? 0 : -1}
          {...this.behaviorProps}
        >
          <Icon name={iconName} />
        </button>
      );
    }
  },
);
