import { createSelector } from 'reselect';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { isSubscriptionButtonVisible } from '../components/overlay-texts/channel/subscribe-button-text-utils';
import {
  isSubscribedTo,
  isSubscriptionWasCancelled,
} from '@wix/wix-vod-shared/dist/src/common/ui-selectors/channel/pricing';

export const canShowSubscription = createSelector(
  getChannelForWidget,
  (channel) => isSubscriptionButtonVisible({ channel }),
);

export const canShowCancelSubscription = createSelector(
  getChannelForWidget,
  (channel) => isSubscribedTo(channel) && !isSubscriptionWasCancelled(channel),
);
