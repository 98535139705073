import React from 'react';
import { MessageWithPrice } from '../components/message-with-price/message-with-price';

type Info = {
  currency: string;
  price: number;
};

export default function formatMessageWithPrice(id: string, info: Info) {
  const { currency, price } = info;

  return <MessageWithPrice currency={currency} price={price} messageId={id} />;
}
