import { VIEW_MODES } from '@wix/wix-vod-constants/dist/common/view-modes';
import { logBi } from '../worker/actions/bi';
import { createProxy } from '../worker/lib';

export const sendLoadComplete = createProxy(
  'sendLoadComplete',
  () => (dispatch) => {
    try {
      const loadingTime = parseInt(performance.now(), 10);

      dispatch(
        logBi('widget.loadComplete', {
          loadingTime,
          viewMode: VIEW_MODES.SITE,
        }),
      );
    } catch (e) {
      console.warn('unable to send widget.loadComplete 71:257');
    }
  },
);
