import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getVideoCoverUrl } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/cover';
import { Picture } from '@wix/wix-vod-shared/dist/src/common/components/picture';

import styles from './video-cover.scss';

export default class VideoCover extends React.Component {
  static propTypes = {
    videoItem: PropTypes.object.isRequired,
    breakpoints: PropTypes.array.isRequired,
    className: PropTypes.string,
  };

  render() {
    const { videoItem, className, breakpoints } = this.props;

    return (
      <div className={classNames(styles.cover, className)}>
        <Picture
          alt={videoItem.title}
          src={getVideoCoverUrl(videoItem)}
          breakpoints={breakpoints}
        />
      </div>
    );
  }
}
