import { createSelector } from 'reselect';

import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getCurrentSiteUser } from '../../../../../../../selectors/current-site-user';

import {
  getAvailableVideoType,
  getFullAccessActionName,
  canPlayFullOrPreview,
} from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';

import getTranslationData from './translation-data';
import { getPlayButtonHandler } from '../../../../../../../utils/get-play-button-handler';

export const createGetPlayButtonConfig = () =>
  createSelector(
    getChannelForWidget,
    getCurrentSiteUser,
    (state, ownProps) => ownProps.videoItem,
    (state, ownProps) => ownProps.onPlayRequest,
    (state, ownProps) => ownProps.onPlayMemberOnlyRequest,
    (channel, user, video, onPlayRequest, onPlayMemberOnlyRequest) => {
      const availableVideoType = getAvailableVideoType(channel, video, user);
      const fullAccessAction = getFullAccessActionName(channel, video, user);

      const callback = getPlayButtonHandler(
        onPlayRequest,
        onPlayMemberOnlyRequest,
        canPlayFullOrPreview(channel, video, user),
        fullAccessAction,
      );

      const translationData = getTranslationData(
        availableVideoType,
        fullAccessAction,
      );

      if (translationData) {
        return {
          callback,
          translationData,
        };
      }

      return null;
    },
  );
