import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CloseIcon from '@wix/wix-vod-shared/dist/src/icons/compiled/components/close';
import { withTranslation } from '@wix/yoshi-flow-editor';
import Input from '@wix/wix-vod-shared/dist/src/widget/ui-components/input/input';

import styles from './custom-size.scss';

class CustomSize extends React.Component {
  static propTypes = {
    dataHook: PropTypes.string,
    className: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onWidthBlur: PropTypes.func,
    onHeightBlur: PropTypes.func,
    onFocus: PropTypes.func,
    inactive: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    const { width, height } = props;

    this.state = {
      width,
      height,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { width, height } = this.state;

    if (String(nextProps.width) !== width) {
      this.setState({ width: nextProps.width });
    }

    if (String(nextProps.height) !== height) {
      this.setState({ height: nextProps.height });
    }
  }

  onCustomWidthChanged = (e) => {
    this.setState({
      width: e.target.value,
    });
  };

  onCustomHeightChanged = (e) => {
    this.setState({
      height: e.target.value,
    });
  };

  onWidthBlur = () => {
    if (!this.state.width) {
      return;
    }

    const width = Number(this.state.width);
    if (width !== this.props.width && !_.isNaN(width)) {
      this.props.onWidthBlur(width);
    }
  };

  onHeightBlur = () => {
    if (!this.state.height) {
      return;
    }

    const height = Number(this.state.height);
    if (height !== this.props.height && !_.isNaN(height)) {
      this.props.onHeightBlur(height);
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.onWidthBlur();
    this.onHeightBlur();
  };

  render() {
    const { dataHook, onFocus, inactive } = this.props;
    const { width, height } = this.state;

    const inputClassList = classNames(styles['text-input'], {
      [styles.inactive]: inactive,
    });

    return (
      <form
        data-hook={dataHook}
        className={styles['custom-sizes']}
        onSubmit={this.onSubmit}
      >
        <Input
          className={inputClassList}
          dataHook="custom-width"
          tabIndex="0"
          value={width}
          onChange={this.onCustomWidthChanged}
          onFocus={onFocus}
          onBlur={this.onWidthBlur}
          ariaLabel={this.props.t('widget.accessibility.widget-width', {
            width,
          })}
        />

        <CloseIcon className={styles.separator} />
        <Input
          className={inputClassList}
          dataHook="custom-height"
          tabIndex="0"
          value={height}
          onChange={this.onCustomHeightChanged}
          onFocus={onFocus}
          onBlur={this.onHeightBlur}
          ariaLabel={this.props.t('widget.accessibility.widget-height', {
            height,
          })}
        />

        <input type="submit" className={styles.submit} />
      </form>
    );
  }
}

export default withTranslation()(CustomSize);
