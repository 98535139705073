import { VideoSources } from '../../redux/types';
import DeviceTypes from '../../constants/device-types';

const SOURCES_INTERACTIONS_MAPPING = {
  [VideoSources.Computer]: 'VIDEO_PLAY_COMPUTER',
  [VideoSources.Youtube]: 'VIDEO_PLAY_YOUTUBE',
  [VideoSources.Vimeo]: 'VIDEO_PLAY_VIMEO',
  [VideoSources.Facebook]: 'VIDEO_PLAY_FACEBOOK',
  [VideoSources.Live]: 'VIDEO_PLAY_LIVE',
};

export const getVideoPlayInteractionName = (
  videoSource: VideoSources,
  isMobile: boolean,
): string => {
  const deviceTypePrefix = (
    isMobile ? DeviceTypes.MOBILE : DeviceTypes.DESKTOP
  ).toUpperCase();

  return `${deviceTypePrefix}_${SOURCES_INTERACTIONS_MAPPING[videoSource]}`;
};
