import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { getVideoCoverUrl } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/cover';
import { getChannelCoverUrl } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/channel/cover';
import ChannelCover from '../../../../../containers/channel-cover/channel-cover';

import styles from '../overlay.scss';

export default class OverlayChannelCover extends React.Component {
  static propTypes = {
    channelData: PropTypes.object.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,

    videoItem: PropTypes.object,
    className: PropTypes.string,
    withFallbackColor: PropTypes.bool,
  };

  static defaultProps = {
    withFallbackColor: false,
  };

  getCoverUrl() {
    const { channelData, videoItem } = this.props;

    return getChannelCoverUrl(channelData) || getVideoCoverUrl(videoItem);
  }

  render() {
    const { width, height, className, channelData } = this.props;

    return (
      <div className={classnames(styles.cover, className)}>
        <ChannelCover
          posterUrl={this.getCoverUrl()}
          mediaInfo={channelData.mediaInfo}
          channelId={channelData.id}
          channelTitle={channelData.title}
          showChannelCover
          width={width}
          height={height}
        />
      </div>
    );
  }
}
