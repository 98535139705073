import _ from 'lodash';
import { createSelector } from 'reselect';
import {
  isScheduledLive,
  isReadyLiveStream,
  isPendingLiveStream,
} from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/live';
import { ItemType } from '../redux/types';

const getVideos = (state) => _.get(state, 'entities.videos');

export const getLiveVideos = createSelector(getVideos, (videos) =>
  _.filter(videos, (video) =>
    _.includes(
      [ItemType.ItemLive, ItemType.ItemScheduled, ItemType.ItemLiveFinalize],
      video.itemType,
    ),
  ),
);

export const getLiveScheduledVideos = createSelector(getLiveVideos, (videos) =>
  _.filter(videos, ({ itemType }) => isScheduledLive({ itemType })),
);

export const getLivePendingVideos = createSelector(getLiveVideos, (videos) =>
  _.filter(videos, ({ liveVideoStatus }) =>
    isPendingLiveStream({ liveVideoStatus }),
  ),
);

export const getLiveStreamingVideos = createSelector(getLiveVideos, (videos) =>
  _.filter(videos, ({ liveVideoStatus }) =>
    isReadyLiveStream({ liveVideoStatus }),
  ),
);
