import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ClampText from '@wix/wix-vod-shared/dist/src/common/components/clamp-text/clamp-text';

import PlayButton from '../buttons/play-button';
import PurchaseButton from '../purchase-button/purchase-button';
import LiveLabel from '../../../../../containers/live-label/live-label';
import NoVideosOverlay from '../no-videos-overlay/no-videos-overlay';

import PlayIcon from '../../../../assets/play.svg';
import styles from './cover-actions-overlay.scss';
import getBuyClickHandler from '../../../../../utils/get-buy-click-handler';

import { isButtonsTextShown } from '../../../../../selectors/app-settings';

const mapStateToProps = (state) => ({
  isTextShown: isButtonsTextShown(state),
  isPlaybackModuleLoaded: state.modules.playbackModuleLoaded,
});

export default connect(mapStateToProps)(
  class CoverActionsOverlay extends React.Component {
    static propTypes = {
      channelData: PropTypes.object,
      videoItem: PropTypes.object,
      currentSiteUser: PropTypes.object,

      onSignInRequested: PropTypes.func,
      onCoverClick: PropTypes.func,
      onBuyClick: PropTypes.func,
      onSubscribeClick: PropTypes.func,
      onMemberSignUp: PropTypes.func,
      onPlayRequestedAllowed: PropTypes.func,
      onPlayRequestedDisallowed: PropTypes.func,

      showChannelCover: PropTypes.bool,
      isChannelCoverView: PropTypes.bool,
      isFirstVideo: PropTypes.bool,
      isPlaybackModuleLoaded: PropTypes.bool,
      isTextShown: PropTypes.bool,

      title: PropTypes.string,
    };

    static defaultProps = {
      onSubscribeClick: _.noop,
    };

    stopPropagation(e) {
      e.stopPropagation();
    }

    handlePurchaseClick = () => {
      const { videoItem, channelData, onBuyClick, onSubscribeClick } =
        this.props;
      const handler = getBuyClickHandler({
        purchaseFn: onBuyClick,
        subscribeFn: onSubscribeClick,
        videoItem,
        channelData,
      });

      handler();
    };

    renderPurchaseButton() {
      const { videoItem, isTextShown } = this.props;

      const iconStyles = classNames(styles.icon, {
        [styles.withText]: isTextShown,
      });

      return (
        <PurchaseButton
          onClick={this.handlePurchaseClick}
          videoItem={videoItem}
          iconClassName={iconStyles}
          className={classNames(styles['central-button'], {
            [styles.visible]: this.props.isPlaybackModuleLoaded,
          })}
        />
      );
    }

    renderPlayButton() {
      const {
        videoItem,
        onMemberSignUp,
        onPlayRequestedAllowed,
        onPlayRequestedDisallowed,
        isChannelCoverView,
        isTextShown,
      } = this.props;

      const iconStyles = classNames(styles.icon, {
        [styles.withText]: isTextShown,
      });

      return (
        <PlayButton
          videoItem={videoItem}
          onMemberSignUp={onMemberSignUp}
          onPlayRequestedAllowed={onPlayRequestedAllowed}
          onPlayRequestedDisallowed={onPlayRequestedDisallowed}
          isChannelCoverView={isChannelCoverView}
          icon={<PlayIcon className={iconStyles} />}
          className={classNames(styles['central-button'], {
            [styles.visible]: this.props.isPlaybackModuleLoaded,
          })}
        />
      );
    }

    renderLiveLabel() {
      const { videoItem } = this.props;
      return (
        <LiveLabel
          itemType={videoItem.itemType}
          liveVideoStatus={videoItem.liveVideoStatus}
          videoTitle={videoItem.title}
          startTime={videoItem.dateStartLive}
          isSmall
          smallClassName={styles['live-scheduled-label']}
          className={styles['live-label']}
        />
      );
    }

    renderTitle() {
      const { title, videoItem } = this.props;

      return (
        title && (
          <div
            data-hook="title"
            className={classNames(styles.title, {
              [styles['with-video']]: videoItem,
            })}
          >
            <ClampText text={title} clampOptions={{ lineClamp: 3 }} />
          </div>
        )
      );
    }

    render() {
      const { title, onCoverClick, videoItem } = this.props;

      if (!videoItem) {
        return <NoVideosOverlay title={title} />;
      }

      const buttonsClassName = classNames(styles['central-buttons'], {
        [styles['no-title']]: !title,
      });

      return (
        <div className={styles.overlay}>
          <div data-hook="labels" className={styles.labels}>
            {this.renderLiveLabel()}
          </div>
          <div
            data-hook="central-area"
            className={styles['central-area']}
            onClick={onCoverClick}
          >
            {this.renderTitle()}
            <div
              data-hook="central-buttons"
              className={buttonsClassName}
              onClick={this.stopPropagation}
            >
              {this.renderPlayButton()}
              {this.renderPurchaseButton()}
            </div>
          </div>
        </div>
      );
    }
  },
);
