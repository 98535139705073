import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import CloseButton from '@wix/wix-vod-shared/dist/src/widget/ui-components/close-button/close-button';
import { canShareVideo } from '../../../../../selectors/video-share';
import ShareMobile from '../../../../components/share-mobile/share-mobile';

import { LinkButton } from '../../../../../components/buttons/buttons';
import ShareIcon from '../../../../assets/share.svg';
import styles from './actions-strip.scss';

import { withTranslation } from '@wix/yoshi-flow-editor';

const mapStateToProps = (state) => ({ showVideoShare: canShareVideo(state) });

export default withTranslation()(
  connect(mapStateToProps)(
    class ActionsStrip extends React.Component {
      static propTypes = {
        className: PropTypes.string,
        onCloseClick: PropTypes.func,
        videoItem: PropTypes.object,
        sharePopupTopPositon: PropTypes.number,
        showVideoShare: PropTypes.bool,
        channelData: PropTypes.object,
      };

      renderShareButton() {
        const { channelData, videoItem, sharePopupTopPositon } = this.props;

        return (
          <ShareMobile
            videoItem={videoItem}
            channelData={channelData}
            top={sharePopupTopPositon}
          >
            <LinkButton
              dataHook="share-video-item-action"
              className={styles['share-icon']}
              ariaLabel={this.props.t('widget.accessibility.share')}
            >
              <ShareIcon />
            </LinkButton>
          </ShareMobile>
        );
      }

      renderCloseButton() {
        const { onCloseClick } = this.props;

        return (
          <CloseButton
            onClick={onCloseClick}
            dataHook="close-video-player-action"
            theme="light"
            className={styles['close-icon']}
            ariaLabel={this.props.t(
              'widget.compact-view.components.action-bar.close',
            )}
          />
        );
      }

      render() {
        const { className, showVideoShare } = this.props;

        return (
          <div
            className={classnames(className, styles['actions-strip'])}
            data-hook="player-actions-strip"
          >
            {showVideoShare && this.renderShareButton()}
            {showVideoShare && <div className={styles.divider}>|</div>}
            {this.renderCloseButton()}
          </div>
        );
      }
    },
  ),
);
