import { createSelector } from 'reselect';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getCurrentSiteUser } from '../../../../selectors/current-site-user';
import {
  getAvailableVideoType,
  AVAILABLE_VIDEO_TYPES,
  FULL_ACCESS_ACTION_NAMES,
  getFullAccessActionName,
  canPlayFullOrPreview,
} from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/video-access';
import { isLiveVideo } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/video-origin';
import purchaseTranslations from '../constants/purchase-translations';
import renderSubscribeButtonText from '../../../../components/overlay-texts/channel/subscribe-button-text';
import renderPurchaseButtonText from '../../../../components/overlay-texts/purchase-button-text';
import { isVerticalLayoutMobile } from '../../../../selectors/app-settings';

const getVideo = (state, props) => props.videoItem;
const isChannelView = (state, props) => props.isChannelCoverView;

export const playButtonText = createSelector(
  [getChannelForWidget, getVideo, getCurrentSiteUser, isChannelView],
  (channel, video, currentSiteUser, isChannelCoverView) => {
    const videoType = getAvailableVideoType(channel, video, currentSiteUser);

    if (videoType === AVAILABLE_VIDEO_TYPES.LIVE_STREAMING) {
      return 'widget.overlay-buttons.watch-live';
    }
    if (videoType === AVAILABLE_VIDEO_TYPES.FULL) {
      return isChannelCoverView
        ? 'widget.overlay-buttons.start-watching'
        : 'widget.overlay-buttons.play-video';
    }
    if (videoType === AVAILABLE_VIDEO_TYPES.TRAILER) {
      return 'widget.overlay-buttons.trailer';
    }
    if (videoType === AVAILABLE_VIDEO_TYPES.FIRST_20_SEC) {
      return 'shared.preview';
    }

    const fullAccessAction = getFullAccessActionName(
      channel,
      video,
      currentSiteUser,
    );
    if (fullAccessAction === FULL_ACCESS_ACTION_NAMES.SIGN_IN) {
      if (isLiveVideo(video)) {
        return 'widget.overlay-buttons.watch-live';
      }
      return isChannelCoverView
        ? 'widget.overlay-buttons.start-watching'
        : 'widget.overlay-buttons.play-video';
    }

    return null;
  },
);

export const playButtonAction = createSelector(
  [
    getChannelForWidget,
    getVideo,
    getCurrentSiteUser,
    (state, props) => props.onMemberSignUp,
    (state, props) => props.onPlayRequestedAllowed,
    (state, props) => props.onPlayRequestedDisallowed,
  ],
  (
    channel,
    video,
    currentSiteUser,
    onMemberSignUp,
    onPlayRequestedAllowed,
    onPlayRequestedDisallowed,
  ) => {
    if (
      getFullAccessActionName(channel, video, currentSiteUser) ===
      FULL_ACCESS_ACTION_NAMES.SIGN_IN
    ) {
      return onMemberSignUp;
    }
    if (canPlayFullOrPreview(channel, video, currentSiteUser)) {
      return onPlayRequestedAllowed;
    }
    return onPlayRequestedDisallowed;
  },
);

export const subscribeButtonText = createSelector(
  [getChannelForWidget, getVideo],
  (channel, video) => renderSubscribeButtonText({ channel, video }),
);

export const purchaseButtonText = createSelector(
  [getChannelForWidget, getVideo, isVerticalLayoutMobile],
  (channel, video, isVerticalLayout) =>
    renderPurchaseButtonText({
      channel,
      video,
      translations: purchaseTranslations,
      isVerticalLayoutMobile: isVerticalLayout,
    }),
);
