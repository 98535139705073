import React from 'react';
import LayoutSwitcher from './layouts/layout-switcher';
import { Direction } from './containers/direction';
import { widgetWrapper } from './worker/widgetWrapper';
import { createStore } from './widget.store';

function Component() {
  return (
    <Direction>
      <LayoutSwitcher />
    </Direction>
  );
}

export const DesktopWidgetComponent = widgetWrapper({
  createStore,
  Component,
});
