import { createSelector } from 'reselect';
import { getSiteUrl } from '../redux/hydrated-data/hydrated-data';
import { isSiteMode } from './view-mode';
import { PRODUCTION_HOSTS } from '@wix/wix-vod-constants/dist/env/hosts';

export const getBaseUrl = createSelector(
  getSiteUrl,
  isSiteMode,
  (siteUrl, isSite) => (isSite ? siteUrl : PRODUCTION_HOSTS.manage),
);
