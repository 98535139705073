import { createSelector } from 'reselect';
import {
  getCompId,
  getFullSiteUrl,
  getCurrentPageId,
  getInstanceId,
} from '../redux/hydrated-data/hydrated-data';
import getUrlToVideo from '@wix/wix-vod-shared/dist/src/common/utils/get-url-to-video';
import {
  getUrlToChannel,
  GetUrlToChannelParams,
} from '@wix/wix-vod-shared/common';
import { RootState } from '../redux/root-reducer';
import { Video } from '../redux/types/video';

const getUrlToVideoSafe = (videoItem: Video, params: GetUrlToChannelParams) => {
  if (!videoItem) {
    return null;
  }
  return getUrlToVideo(videoItem, params);
};

export const getShareMode = (state: RootState) => state.shareOverlay.shareMode;
export const isShareOverlayOpen = (state: RootState) =>
  state.shareOverlay.isOpen;
export const getInstanceInfo = createSelector(
  getInstanceId,
  getCompId,
  getCurrentPageId,
  (instanceId, componentId, sitePageId) => ({
    instanceId,
    componentId,
    sitePageId,
  }),
);

export const getVideoUrl = createSelector(
  (state: RootState, ownProps: { videoItem: Video }) => ownProps.videoItem,
  getCompId,
  getFullSiteUrl,
  (videoItem, compId, siteUrl) =>
    getUrlToVideoSafe(videoItem, { compId, siteUrl }),
);

export const getChannelUrl = createSelector(
  getCompId,
  getFullSiteUrl,
  (compId, siteUrl) => getUrlToChannel({ siteUrl, compId }),
);
