import { toError } from '../../parsers/transport-error';
import {
  keysToCamelCase,
  keysToSnakeCase,
} from '@wix/wix-vod-shared/dist/src/common/utils/object';
import { ChannelType } from '../../../redux/types';
import { parseChannel } from './parsers';
import { isGraphAPIEnabled } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';
import {
  PurchasesService,
  Purchase,
} from '@wix/wix-vod-gql-api/dist/src/public/purchases/purchases';
import { PaymentType } from '@wix/wix-vod-constants/dist/api-types/enums';
import { getPublicApi } from '../index';

export const getVideoPreviewUrls = (channelId: string, instance: string) => {
  const { oldPublicChannelService } = getPublicApi();
  return oldPublicChannelService
    .getVideoPreviewUrls(channelId, instance)
    .then((response) => response.data);
};

export type MemberInfoItem = {
  dgsInfo: any;
  listId: string;
  itemId?: string;
};

export type MemberInfoData = {
  data: {
    items: MemberInfoItem[];
  };
};

export const getMemberInfo = async (
  baseUrl: string,
  channelId: string,
  instance: string,
  hydratedMemberInfo?: unknown,
): Promise<MemberInfoData> => {
  const { oldPublicChannelService } = getPublicApi();
  if (isGraphAPIEnabled()) {
    const purchasesService = new PurchasesService(instance, baseUrl);
    const purchases = await purchasesService.getPurchases();
    const items = purchases.map((purchase: Purchase) => {
      const item: MemberInfoItem = {
        dgsInfo: {
          [purchase.type]: purchase,
        },
        listId: channelId,
      };

      // in some cases we check if itemId is present - means that it is channel subscription (not video rent/sale) :)
      if (purchase.type !== PaymentType.SUBSCRIPTION) {
        item.itemId = purchase.id;
      }

      return item;
    });

    return {
      data: {
        items,
      },
    };
  }

  try {
    return keysToCamelCase(
      hydratedMemberInfo ||
        (await oldPublicChannelService.getMemberInfo(channelId, instance)),
    );
  } catch (error: any) {
    return toError(error.response);
  }
};

export const getChannel = async (
  channelId: string,
  hydratedChannel: any,
  templateMetaSiteId?: string,
) => {
  const { oldPublicChannelService } = getPublicApi();

  const response =
    hydratedChannel ||
    (await oldPublicChannelService.getInfo(channelId, undefined, {
      params: { metaSiteId: templateMetaSiteId },
    }));

  try {
    const channel = keysToSnakeCase(response.data);
    channel.youtube_data = keysToCamelCase(channel.youtube_data);

    const { custom_cover_url, cover_url, featured_item, channel_type } =
      channel;

    if (
      !custom_cover_url &&
      !cover_url &&
      featured_item &&
      channel_type !== ChannelType.YoutubeFeed
    ) {
      channel.cover_url = extractCoverFromVideoItem(channel.featured_item);
    }

    return { data: parseChannel(channel) };
  } catch (error: any) {
    return toError(error.response);
  }
};

const extractCoverFromVideoItem = (videoItem: any) =>
  videoItem.custom_cover_url || videoItem.cover_url;
